export const crossReactivityMap = [
    {
        host: 'Donkey',
        species_reactivity: 'Anti-Human',
        cross_reactivity: 'Bovine, Chicken, Goat, Guinea Pig, Syrian Hamster, Horse, Mouse, Rabbit, Rat, Sheep Serum Proteins'
    },
    {
        host: 'Donkey',
        species_reactivity: 'Anti-Mouse',
        cross_reactivity: 'Bovine, Chicken, Goat, Guinea Pig, Syrian Hamster, Horse, Mouse, Rabbit, Rat, Sheep Serum Proteins'
    },
    {
        host: 'Donkey',
        species_reactivity: 'Anti-Rabbit',
        cross_reactivity: 'Bovine, Chicken, Goat, Guinea Pig, Syrian Hamster, Horse, Human, Mouse, Rat, Sheep Serum Proteins'
    },
    {
        host: 'Goat',
        species_reactivity: 'Anti-Human',
        cross_reactivity: 'Based on immunoelectrophoresis and/or ELISA, the antibody reacts with whole molecule human IgG. It also reacts with the light chains of other human immunoglobulins. No antibody was detected against non-immunoglobulin serum proteins. The antibody may cross-react with immunoglobulins from other species.'
    },
      {
        host: 'Goat',
        species_reactivity: 'Anti-Mouse',
        cross_reactivity: 'Human, Bovine, Horse, Rabbit, Swine Serum Proteins'
    },
    {
        host: 'Goat',
        species_reactivity: 'Anti-Rabbit',
        cross_reactivity: 'Human, Mouse, Rat Serum Proteins'
    },
    {
        host: 'Rabbit',
        species_reactivity: 'Anti-Mouse',
        cross_reactivity: 'Human Serum Proteins'
    }
]