import styled from "styled-components";
import { toAbsoluteUrl } from "../../../utils/assetHelper";

export const AboutHeader = styled.div`
margin-top: 20px;
h1{
    font-size: 48px;
    font-weight: 800;
    margin-bottom: 16px;
    text-align: center;
    color: #fff;

    span{
        color: var(--primary-color);
    }
    
    @media screen and (max-width: 1100px) {
        font-size: 36px;
    }
}
`

export const AboutContent = styled.div`
width: 98vw;
margin: 32px auto;

.container{
padding-left: 0;
padding-right: 0;
}    

.azure-section-four-container{
background: linear-gradient(102deg, rgba(63, 27, 208, 0.05) 4.47%, rgba(215, 76, 16, 0.05) 54.56%);
padding: 60px 44px !important;

@media screen and (max-width: 1100px) {
    padding: 60px 1.2rem !important;
}

h2{
font-size: 48px;
font-style: normal;
font-weight: 800;
line-height: 60px;
text-align: center;
}

h5{
font-size: 20px;
line-height: 28px;
font-weight: 700;
}

p{
margin-top: 16px;
font-size: 20px;
font-style: normal;
font-weight: 400;
line-height: 28px;
text-align: center;

span{
    color: var(--primary-color);
    font-weight: 700;
}

}

}

.methods-header{
    display: grid;
    grid-template-columns: 150px auto;
    gap: 50px;
    width: 100%;

@media screen and (max-width:1100px) {
    grid-template-columns: 120px auto;
    gap: 5px;
}

.inner-header{
display: flex;
justify-content: space-between;
width: 100%;
}

ul {
padding-left: 40px;
display: flex;
flex-direction: column;
gap: 16px;
font-size: 20px;
list-style: disc;
margin-top: 16px;
}

p{
text-align: left;
display: flex;
gap: 5px;
align-items: start;

span{
color:#1e1e1e;
font-weight: 400;
}

}




}


.left-container > .solutions-text > p{
margin-top: 16px;
font-size: 20px;
font-style: normal;
font-weight: 400;
line-height: 28px;
width: 100%;

span{
    color: var(--primary-color);
    font-weight: 700;
}
}

.card1,.card2 > p{
font-size: 20px;
font-style: normal;
font-weight: 400;
line-height: 28px;

span{
    font-weight: 700;
    color: var(--primary-color);
}

.purple{
    color: #3F1BD0;
}

}

@media screen and (min-width:2160px) {
    .background-image{
        height: 800px !important;
    }
}



.services-section-two-container {
    margin: 0px 0 32px 0 ;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 32px;
    align-items: start;
    padding-top: 0;
    padding-bottom: 0;

    @media screen and (max-width:1100px) {
        grid-template-columns: 1fr;
        padding-left: 1.2rem;
        padding-right: 1.2rem;
    }


    .left-container,
    .right-container {
        display: flex;
        flex-direction: column;
        gap: 32px;
        height: 100%;
    }

    .card1,
    .card2 {
        width: 100%;
        display: flex;
        flex-direction: column;
        /* align-items: center; */
        background: linear-gradient(0deg, rgba(241, 238, 252, 0.48) 0%, rgba(241, 238, 252, 0.48) 100%), #FFF;
        border-radius: 12px;
        padding: 44px 96px;
        justify-content: center;
        height: 100%;

        @media screen and (max-width:1100px) {
        padding: 44px 1.2rem;
        height: max-content !important;

        h3{
            font-size: 30px !important;
        }
    }

        h3 {
            font-size: 34px;
            line-height: 50px;
            color: #3F1BD0;
            text-align: left;
            margin-bottom: 16px;
        }

        .points-container {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 12px;
            width: 100%;
        }

        .points {
            display: flex;
            align-items: start;
            gap: 12px;
            position: relative;
            width: 100%;


            img {
                width: 12px;
                height: 12px;
                transform: translateY(5px);
            }

            a{
            width: 100%;
            }

            p {
                font-size: 20px;
                line-height: 24px;
                width: 100%;

                span {
                    color: #3F1BD0;
                    font-weight: 600
                }
            }
        }
    }

    .card2 {
        background: linear-gradient(0deg, rgba(255, 242, 229, 0.48) 0%, rgba(255, 242, 229, 0.48) 100%), #FFF;

        h3 {
            color: var(--primary-color);
        }

        .points {
            p {
                span {
                    color: var(--primary-color)
                }
            }
        }
    }

}

.thermo-second-container{
.left-container, .right-container {
    .card1,.card2{
    @media screen and (max-width:1100px) {
        height: max-content !important;
    }
}     
} 
}

`

export const Wrapper = styled.div`
.bio-container{
    background: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url(${toAbsoluteUrl('/media/microscopy/header.png')}) no-repeat;
background-size: 100% 100%;
background-position: bottom center;
margin-bottom: 48px;

@media screen and (max-width:1100px) {
background-size: cover;
background-position: left bottom;
}
}

`

export const Content = styled.div`
       display: grid;
       gap: 48px;
@media screen and (max-width: 1100px) {
       width: 98vw;
       margin: 0 auto;
    }
@media screen and (min-width: 2160px) {
      padding: 0 25rem;  
    }
`

export const CardContainer = styled.div`
background: ${props => props.background ? props.background : props.gradientBg ? 'linear-gradient(102deg, rgba(63, 27, 208, 0.05) 4.47%, rgba(215, 76, 16, 0.05) 54.56%)' : "#F1EEFC"};
color: ${props => props.textColor || "#1E1E1E"};
display: flex;
flex-direction: column;
justify-content: ${props => props.justifyContent || "center"};
align-items: ${props => props.alignItems || "center"};
padding: ${props => props.padding || "32px"};
border-radius: ${props => props.radius || "20px"};
width: ${props => props.width || "100%"};
gap: ${props => props.gap || 0};
margin: 0 auto;

@media screen and (max-width: 1100px) {
    padding: 16px;
    width: 100%;

    img {
        width: 50% !important;
         height: 100% !important;
         object-fit: contain;
        }
}

@media screen and (max-width: 490px) {
    img {
        width: 100% !important;
         height: 100% !important;
        }
}

@media screen and (min-width: 2160px) {
    margin: 0;
}

h2 {
    font-size: 36px;
    font-weight: 700;
    line-height: 45px;
    text-align: ${props => props.textAlignH2 || "center"};
    margin-bottom: ${props => props.mb_h2 || "0"};

    span {
        background:linear-gradient(227deg, #DC4405 0%, #FF8D24 85.6%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }

    @media screen and (max-width: 1100px) {
        font-size: 24px;  
    }
}

h3 {
    font-size: 24px;
    font-weight: 600;
    line-height: 28px;
    text-align: ${props => props.textAlignH3 || "center"};
    margin-bottom: ${props => props.mb_h3 || "0"};
    span{
        color: ${props => props.color || '#1e1e1e'}
    }
    @media screen and (max-width: 1100px) {
    font-size: 20px;
    text-align: center;
    }
}

.gradient-text{
        span {
        background:linear-gradient(227deg, #DC4405 0%, #FF8D24 85.6%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        }
}

h4 {
font-weight: ${props => props.fontWeightH4 || 500};
font-size: 20px;
line-height: 28px;
text-align: ${props => props.textAlignH4 || "center"};
margin-bottom: ${props => props.mb_h4 || "0"};

span{
font-weight: 600;
}

@media screen and (max-width: 1100px) {
    text-align: center;
    }
}

h5 {
font-weight: ${props => props.fontWeightH5 || "500"};
font-size: 16px;
line-height: 24px;
text-align: ${props => props.textAlignH5 || "left"};
margin-bottom: ${props => props.mb_h5 || "0"};
}

ul {
list-style: disc;
display: flex;
flex-direction: ${props => props.listDirection || 'column'};
gap: ${props => props.listGap || 0};
@media screen and (max-width: 1100px) {
    padding: 0 16px;
}

li{
font-size: 20px;
font-weight: 600;
span {
    background:linear-gradient(227deg, #DC4405 0%, #FF8D24 85.6%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
}

.list::marker{
    color: #FF8D24;
}
}

p {
font-weight: 400;
font-size: ${props => props.fontSizeP || "20px"};
line-height: 28px;
text-align: ${props => props.textAlignP || "center"};
margin: 0 auto;
margin-bottom: ${props => props.mb_p || "0"};
width: ${props => props.widthP || "100%"};

@media screen and (max-width: 1100px) {
    width: 100%;
}
span {
    font-weight: 700;
}
.gradient{
    background:linear-gradient(227deg, #DC4405 0%, #FF8D24 85.6%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
}

small {
font-size: 12px;
font-weight: 400;
line-height: 24px;
text-align: ${props => props.textAlignSmall || "left"};
margin-bottom: ${props => props.mb_small || "0"};

span {
    font-weight: 700;
}
}

.flex-wrapper {
    display: flex;
    flex-direction: ${props => props.flexDirection || "column"};
    gap: ${props => props.wrapperGap || "5px"};
    align-items: ${props => props.alignItems || "start"};
    justify-content: ${props => props.justifyContent || "start"};
    margin-bottom: ${props => props.mbWrapper || "0"};
    @media screen and (max-width: 1100px) {
    padding: 0 16px;
    flex-direction: column;
    }
}
`

export const HeadingContainer = styled.h2`
font-size: 36px;
font-weight: 600;
line-height: 45px;
text-align: center;
width: ${props => props.width || "100%"};;
margin: 0 auto;

@media screen and (max-width: 1100px) {
    font-size: 24px;
}
span {
    background:linear-gradient(227deg, #DC4405 0%, #FF8D24 85.6%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
`

export const WrapperSmallGap = styled.div`
    display: flex;
    flex-direction: ${props => props.flexDirection || "column"};
    gap: ${props => props.gap || "24px"};
    align-items: ${props => props.alignItems || "start"};
    justify-content: ${props => props.justifyContent || "start"};
    margin-bottom: ${props => props.mbWrapper || "0"};
    width: ${props => props.width || "100%"};
    margin: 0 auto;
    margin-bottom: ${props => props.marginBottom || "0"};

    @media screen and (max-width: 1100px) {
       flex-direction: column;
       width: 100%;
    }

p {
    text-align: ${props => props.textAlignP || "center"};

    span {
        font-weight: 700;
    }
}

img {
    width: ${props => props.imgWidth || "55%"};
    object-fit: cover;
    @media screen and (max-width: 1100px) {
        width: 80%; 
    }
}

`

export const ImageContainer = styled.div`
    position: relative;
    width: ${props => props.width || "100%"};
    margin: 16px 0;

    .image-label {
        position: absolute;
        top: ${props => props.imgTop || 0};
        left: ${props => props.imgLeft || 0};
    }

    img{
        width: 100%;
        height: 580px;
        object-fit: inherit;

        @media screen and (max-width: 1100px) {
         height: 100% !important;
         object-fit: contain;
        }
    }

    .video-container{
        position: absolute;
        top: ${props => props.videoTop || 0};
        left: ${props => props.videoLeft || 0};
        right: ${props => props.videoRight || 0};
        bottom: ${props => props.videoBottom || 0};
        width: max-content;
        height: max-content;

        @media screen and (max-width: 1100px) {
        top: ${props => props.videoTopMobile || 0};
        left: ${props => props.videoLeftMobile || 0};
        right: ${props => props.videoRightMobile || 0};
        bottom: ${props => props.videoBottomMobile || 0};
        }
       
        video{
            border: 1px solid #dc4405;
            border-radius: 12px;

            @media screen and (max-width: 1100px) {
                width: 100px;
            }
        }
    }
`