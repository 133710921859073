import React, { useState, useEffect, useContext } from 'react'
import Layout from '../../../components/layout'
import { Wrapper, ServiceHeader, ServiceContent, ServiceStepsContainer, CardContainer, HeadingContainer, WrapperSmallGap } from './style'
import { Link } from 'react-router-dom'
import { toAbsoluteUrl } from '../../../utils/assetHelper'
import { AuthContext } from '../../../context/auth'
import { contactApi } from '../../../api'
import Input from "../../../components/Input"
import { CountryDropdown, StateDropdown } from '../../../components/DropDowns'
import axios from 'axios'
import { Footer } from '../../../components/Footer'

const Index = () => {
  const { setIsLoading, setNotification } = useContext(AuthContext)
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [company, setCompany] = useState('');
  const [country, setCountry] = useState('United States');
  const [state, setState] = useState('');
  const [city, setCity] = useState('');
  const [topic, setTopic] = useState('Identifyn™ Services');
  const [description, setDescription] = useState('');
  const [cities, setCities] = useState([]);


  useEffect(() => {
    if (state === undefined) return
    if (state) {
      axios.post('https://countriesnow.space/api/v0.1/countries/state/cities', {
        country: country,
        state: state
      }).then((res) => {
        setCities(res.data.data)
      }).catch((err) => {
        console.error(err)
      })
    }
  }, [state])



  const sendContact = async () => {
    setIsLoading(true)
    if (email && RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/).test(email)) {
      const data = {
        first_name: firstName,
        last_name: lastName,
        email,
        company,
        country,
        state,
        city,
        topic,
        message: description,
        email_to:'services@identifyn.com'
      }
      const response = await contactApi(data)
      if (response?.status === 200) {
        setFirstName('')
        setLastName('')
        setEmail('')
        setCompany('')
        setCountry('United States')
        setState('')
        setCity('')
        setTopic('')
        setDescription('')
        setIsLoading(false)
        setNotification({
          type: 'success',
          message: 'Message sent successfully!'
        })
      } else {
        setIsLoading(false)
        setNotification({
          type: 'red',
          message: 'Something went wrong, please try again!'
        })
      }
    } else {
      setIsLoading(false)
      setNotification({
        type: 'red',
        message: 'Please enter a valid Email Address'
      })
    }
  }






  return (
    <Layout>
      <Wrapper>
      <ServiceHeader>
        <img src={toAbsoluteUrl("/media/services/header.png")} alt="header" />
        <div className='subhero-img'>
        <img src={toAbsoluteUrl("/media/services/header1.png")} alt="header1" />
        </div>
      </ServiceHeader>
      <ServiceContent>
        <h2 className="sub-headng">
         <span>Identifyn™ Collaborates on Multimodal Applications to Realize your Research Vision, Encompassing Experience, Application and the most Powerful Set of Tools​ in Scientific Industry​</span>
        </h2>
        <div className="header-card-container">
          <div className="card">
          Identifyn’s™ Proprietary Bench to Body™ Methodology connects bench research to clinical applications, supporting predictive pharmaceutical discoveries with a focus on oncology and other disease-related pathways
          </div>
          <div className="card">
          Identifyn’s™ Eminent Scientists will Collaborate​ with you in Developing Hypothesis ​and Experimental Design  ​
          </div>
          <div className="card">
          Discover Identifyn's™ Meticulously Vetted Research Reagents, or let us Collaborate using your Trusted Reagents
          </div>
        </div>
        <div className="antibodies-card-container">
          <div className="antibody-card">
            <div className="text-card">
              <h4><span>Primary Antibodies</span></h4>
              <p>
              Identifyn’s™ <span>De novo target</span> specific, <span>super resolution vetted</span>, ​primary antibodies encompass <span>oncological ​pathways</span>
              </p>
            </div>
            <img src={toAbsoluteUrl("/media/services/card.png")} alt="Rabbit anti-Human γH2Ax Recombinant Monoclonal, Human Fc, IgG - Widefield SIM" />
          </div>
          <div className="antibody-card">
            <div className="text-card">
              <h4><span>Conjugated Primary Antibodies</span></h4>
              <p>
                Identifyn’s™ SRM Alexa Fluor™ <span>proprietary conjugated​ primary antibodies</span>, targeting oncological pathways, with <span>superior specificity and brightness</span>
              </p>
            </div>
            <img src={toAbsoluteUrl("/media/services/card1.png")} alt="Rabbit anti-Human γH2Ax Recombinant Monoclonal, Human Fc, IgG - Widefield SIM" />
          </div>
          <div className="antibody-card">
            <div className="text-card">
              <h4><span>Conjugated Secondary Antibodies</span></h4>
              <p>
                Identifyn’s™ SRM Alexa Fluor™ proprietary <span>conjugated ​secondary antibodies</span>, with <span>higher dye ratios</span> and <span>proven higher performance​</span>
              </p>
            </div>
            <img src={toAbsoluteUrl("/media/services/card2.png")} alt="Rabbit anti-Human γH2Ax Recombinant Monoclonal, Human Fc, IgG - Widefield SIM" />
          </div>
        </div>
      <ServiceStepsContainer>
        <HeadingContainer>
        <span>Your Research Journey Starts Now - Take the First Step Towards Discovery</span>
        </HeadingContainer>
        <CardContainer  mb_h2={'16px'} mb_h4={'24px'} mb_p={'16px'} textAlignSmall={'right'} background="#FFF2E5" width={'55%'} padding={'32px 32px 10px 32px'}>
        <h2><span>Step 1</span></h2>
        <h4 >Bennett Standards for Optical Microscopy Methods™​</h4>
        <p>Two decades of scientific research have led to peer reviewed* methodology providing ease of sample prep with repeatability and advanced quantification capabilities</p>
        <small>*Immunofluorescence Imaging of DNA Damage Response Proteins, Methods 2013 Jun - Bennett</small>
        </CardContainer>
        <CardContainer  mb_h2={'16px'} mb_h4={'24px'} mb_p={'16px'}  background="#FFF2E5" width={'55%'} mbWrapper={'16px'}>
        <h2><span>Step 2</span></h2>
        <h4>Identifyn™ Encompasses Experience, Application and the most Powerful Set of Tools in Scientific Industry​</h4>
        <p>Advanced Fluorescence Imaging and quantification capability with single molecule sensitivity</p>
        <div className='flex-wrapper'>
        <h5>Dr. Brian T. Bennett CSO</h5>
        <small><em>H2AX chromatin structures and their response to DNA damage revealed by 4Pi microscopy, PNAS 2006 Nov - Bennett</em></small>
        <small><em>Three-dimensional sub-100 nm resolution fluorescence microscopy of thick samples, Nature 2008 - Bennett</em></small>
        <small><span>Patent -</span> <em>Microscopy System and Method for Creating Three Dimensional Images using Probe Molecules US8994807B2</em></small>
        <small><span>Patent -</span> <em> Methods for Designing Immunofluorescent Cellular Research Tests in a Graphical User Interface - Patent Pending 2024</em></small>
        <small><span>Patent -</span> <em> A Chemosensitivity Assay - Methods for Single Molecule Pharmaceutical Titrations in Human Tissue - Patent Pending 2024</em></small>
        </div>
        <div className='flex-wrapper'>
        <h5>Dr. Shreyasi Thakur, Director of Microscopy</h5>
        <small><em>Dynein Clusters into Lipid Microdomains on Phagosomes to Drive Rapid Transport toward Lysosomes, Cell Press 2015 - Thakur</em></small>
        <small><em>Aberrant chromatin reorganization in cells from diseased fibrous connective tissue in response to altered chemomechanical cues, Thakur et al, Nat Biomed Engineering 2023 Feb - Thakur</em></small>
        </div>
        <div className='flex-wrapper'>
        <h5>Dr. Shalil Khanal, Director of Protein Chemistry</h5>
        <small><em>Nano-fibre Integrated Microcapsules: A Nano-in-Micro Platform for 3D Cell Culture, Scientific Reports 2019 - Khanal</em></small>
        <small><em>Glycolysis in hepatic stellate cells coordinates fibrogenic extracellular vesicle release spatially to amplify liver fibrosis, Science Advances 2024 - Khanal</em></small>
        </div>
        <div className='flex-wrapper'>
        <h5>Emily Simon, Director of Product Validation</h5>
        <small><em>Discovery and Anti-Inflammatory Activity of a Cyanobacterial Fatty Acid Targeting the Keap1/Nrf2 Pathway, Mar Drugs 2023 - Simon</em></small>
        </div>
        <div className='flex-wrapper'>
        <h5>Dr. Prakash Raut, Senior Scientist</h5>
        <small><em>Phosphatidylinositol 4,5-Bisphosphate Mediates the Co-Distribution of Influenza A Hemagglutinin and Matrix Protein M1 at the Plasma Membrane, Viruses 2022 - Raut</em></small>
        </div>
        <div className='flex-wrapper'>
        <h5>Dr. Komala Shivanna, Scientist - Single Molecule</h5>
        <small><em>Super-Resolution Imaging Reveals the Nanoscale Distributions of Dystroglycan and Integrin Itga7 in Zebrafish Muscle Fibers, Biomedicines 2023 - Shivanna</em></small>
        </div>
        <h5 style={{ textAlign: 'center' }}>Highlighted Publications for the Identifyn™ Team</h5>
        </CardContainer>
        <WrapperSmallGap>
        <CardContainer>
          <h4>Microscopy</h4>
        </CardContainer>
        <CardContainer>
          <h4>Identifyn™ Fluorescence Microscopy Platform offers a Broad Range of Imaging Resolutions enabling Single-Cell Profiling Capability to Localization and Quantification of Protein down to Single-Molecule Level</h4>
        </CardContainer>
        <WrapperSmallGap flexDirection={'row'} width={'80%'} alignItems={'center'}>
        <CardContainer mb_h4={'24px'} textAlignH4={'left'} textAlignP={'left'}>
          <h4>​Widefield and Widefield SIM Resolutions</h4>
          <p><span>ZEISS Axioscope and Apotome</span>, contrary to conventional systems, are <span>equipped with sCMOS cameras</span> that enable the <span>acquisition</span> of <span>large, high-resolution images with low noise</span> and the ability to observe them over <span>fast timescales. Advanced software</span> enhances the Wide field resolution</p>
        </CardContainer>
        <img src={toAbsoluteUrl("/media/services/card3.png")} alt="" style={{width: '40%'}} />
        </WrapperSmallGap>
        <WrapperSmallGap flexDirection={'row'} width={'80%'} alignItems={'center'}>
        <img src={toAbsoluteUrl("/media/services/card4.png")} alt="" style={{width: '40%'}} />
        <CardContainer mb_h4={'24px'} textAlignH4={'left'} textAlignP={'left'}>
          <h4>Confocal and AiryScan Resolutions</h4>
          <p><span>ZEISS LSM 900 combines</span> the traditional <span>resolution of confocal microscopy with the super resolution capabilities of AiryScan Microscopy</span> in a single platform. Suitable for <span>live or fixed cells/tissue/organoids achieving planer sectioning and 120nm</span> of radial resolution at high speed with the help of <span>powerful image reconstruction software</span>. This advancement offers valuable data as we progress <span>into the realm of Super Resolution</span></p>
        </CardContainer>
        </WrapperSmallGap>
        <WrapperSmallGap flexDirection={'row'} width={'80%'} alignItems={'center'}>
        <CardContainer mb_h4={'24px'} textAlignH4={'left'} textAlignP={'left'}>
          <h4>Lattice SIM and SIM² Super Resolution</h4>
          <p><span>Zeiss Elyra 5</span> provides <span>3D super resolution imaging</span> at <span>high speeds</span> suitable for studying <span>live or fixed cells/tissue/organoids up to 100um</span> thick. The technique using <span>Lattice spot pattern</span> illumination <span>increases imaging speed at no signal loss</span>. Equipped with novel reconstruction algorithm <span>SIM²  doubles the spatial resolution to ~60nm</span>​</p>
        </CardContainer>
        <img src={toAbsoluteUrl("/media/services/card5.png")} alt="" style={{width: '40%'}} />
        </WrapperSmallGap>
        <WrapperSmallGap flexDirection={'row'} flexDirectionMobile={'column-reverse'} width={'80%'} alignItems={'center'}>
        <img src={toAbsoluteUrl("/media/services/card6.png")} alt="" style={{width: '40%'}} />
        <CardContainer mb_h4={'24px'} textAlignH4={'left'} textAlignP={'left'}>
          <h4>Standalone NIR Confocal Imaging</h4>
          <p><span>ZEISS LSM 980</span> is equipped with a <span>730 nm laser</span>, enabling <span>imaging in the Near Infrared (NIR)</span> range with <span>capabilities of up to 900nm</span>. This powerful platform provides <span>better Signal to Noise, tissue penetration</span> and combined with <span>LSM Plus post-processing software pushes resolution</span>. Additionally, NIR spectrum imaging capability <span>facilitates multiplexing</span>.​ The <span>LSM 980 is also equipped with AiryScan resolution to 120nm axially</span></p>
        </CardContainer>
        </WrapperSmallGap>
        <WrapperSmallGap flexDirection={'row'} width={'80%'} alignItems={'center'}>
        <CardContainer mb_h4={'24px'} textAlignH4={'left'} textAlignP={'left'}>
          <h4>Single Molecule Localization Microscopy</h4>
          <p><span>Vutara VXL</span> offers intrinsic <span>3D imaging</span> along with an <span>integrated fluidics system</span> for <span>multiplexing</span> suitable to <span>study biological structural details</span> up to <span>15nm radially and 50nm axially</span> in cells/tissue/organoids up to 50um thick. The <span><a href="https://physics.aps.org/articles/v7/104#:~:text=The%202014%20Nobel%20Prize%20in,crystal%20by%20Moerner%20in%201989" target='_blank'>Nobel Prize winning technique</a></span> (STORM, PALM, DNA-PAINT) and instrument <span className="gradient">designed by Identifyn™ CSO Dr. Brian T. Bennett</span> provides localization and precise quantification of proteins at the single-molecule level</p>
        </CardContainer>
        <img src={toAbsoluteUrl("/media/services/card7.png")} alt="" style={{width: '40%'}} />
        </WrapperSmallGap>
        <CardContainer width={'80%'} style={{ alignItems:'center' }}>
          <video src={toAbsoluteUrl("/media/services/vutura.mp4")} controls autoPlay loop muted playsInline></video>
          <p>In December 2008, Dr. Brian T. Bennett, an Assistant University Professor at The University of Utah, demonstrated the first image acquisition using the beta version of what would later become the Vutara VXL. This marked a significant milestone in the development of super-resolution microscopy technology</p>
        </CardContainer>
        </WrapperSmallGap>
        <WrapperSmallGap>
        <CardContainer background={"#EDEDED"}>
          <h4>Protein Chemistry</h4>
        </CardContainer>
        <CardContainer background={"#EDEDED"}>
          <h4>Identifyn™ Sapphire Systems with 405 to NIR Imaging Capability provide a Powerful Platform Designed to Streamline and Enhance Workflow</h4>
        </CardContainer>
        <WrapperSmallGap>
        <WrapperSmallGap flexDirection={'row'} alignItems={'center'} width={'80%'}>
          <WrapperSmallGap>
            <CardContainer background={'#EDEDED'}>
              <p><span>Sapphire</span> explores <span>protein expression, post-translational modifications</span>, and total <span>protein normalization</span>. Cutting-edge applications like <span>2D DIGE, In-Cell Western,</span> and <span>multiplex western blotting</span> for precise protein analysis</p>
            </CardContainer>
            <CardContainer  background={'#EDEDED'}>
              <p><span>Sapphire</span> supports <span>arrays, membrane arrays, and microarrays for high-throughput applications.</span>​ This allows visualization of <span>tissue samples</span> using tissue section Imaging and <span>Fluorescence Immunohistochemistry</span></p>
            </CardContainer>
          </WrapperSmallGap>
          <img src={toAbsoluteUrl('/media/services/card8.png')} style={{width: '40%'}}/>
        </WrapperSmallGap>
        <WrapperSmallGap flexDirection={'row'} alignItems={'center'} width={'80%'}>
          <img src={toAbsoluteUrl('/media/services/card9.png')} style={{width: '40%'}}/>
          <WrapperSmallGap>
            <CardContainer background={'#EDEDED'}>
              <p><span>Sapphire</span> provides expanded capabilities include <span>DNA/RNA workflows, Southern and Northern Blotting,</span> and <span>bacterial plate imaging</span> for comprehensive microbial analysis</p>
            </CardContainer>
            <CardContainer background={'#EDEDED'}>
              <p><span>​With Identifyn™ Sapphire, you gain unparalleled flexibility and functionality for protein and nucleic acid research, all in one versatile platform</span></p>
            </CardContainer>
          </WrapperSmallGap>
        </WrapperSmallGap>
        </WrapperSmallGap>
        </WrapperSmallGap>
        <CardContainer  mb_h2={'16px'} background="#FFF2E5" width={'55%'}>
        <h2><span>Step 3</span></h2>
        <h4>Identifyn™ Data Analysis and Interpretation ​</h4>
        </CardContainer>
        <WrapperSmallGap>
        <CardContainer background={'#EDEDED'}>
          <p><span>Multimodal post-processing</span> applications in <span>Zen, SRX, and Azure Spot software</span>, combined with <span>Identifyn's™ data analysis and interpretation</span> capabilities, offer <span>significant value for scientific discoveries</span>. Additionally, the availability of <span>remote access and data sharing</span> through <span>Identifyn's™ HIVE</span>  enhances <span>collaboration opportunities</span>.​</p>
        </CardContainer>
        <WrapperSmallGap flexDirection={'row'} width={'55%'} justifyContent={'center'} alignItems={'center'}>
          <img src={toAbsoluteUrl('/media/services/card10.png')} />
          <img src={toAbsoluteUrl('/media/services/card11.png')} />
        </WrapperSmallGap>
        </WrapperSmallGap>
        <CardContainer mb_h4={'24px'} width={'80%'} background={'#F4F4F4'}>
          <h4>Identifyn™ Provides Support Designed Specifically for your Scientific Needs</h4>
          <p>At Identifyn™, we deliver comprehensive solutions tailored to your needs. Whether you're looking for full experimental design, tissue culture, protein chemistry, sample preparation, or just a single image, we ensure you get the data you need with confidence and precision.​</p>
        </CardContainer>
        <HeadingContainer>
        <span>Identifyn™ Encompasses Experience, Application and the most Powerful Set of Tools in Scientific Industry</span>
        </HeadingContainer>
      </ServiceStepsContainer>
      </ServiceContent>
      <div className="container contact-container" id='contact' style={{marginBottom:'20px'}}>
          <div className="left-container">
            <div className="solutions-text">
              <h2>Contact Us</h2>
              <Link className="proceed-btn" to={'/contact'}>Proceed to Form</Link>
            </div>
          </div>
          <div className="right-container">
            <div className="form-container">
              <div className="form-wrapper">
                <div
                  style={{ display: "block", width: "100%", marginTop: "20px", marginBottom: '20px' }}
                >
                  <div className="two-column">
                    <Input placeholder="Your First Name" value={firstName} onChange={(e) => setFirstName(e.target.value)} />
                    <Input placeholder="Your Last Name" value={lastName} onChange={(e) => setLastName(e.target.value)} />
                  </div>
                  <Input placeholder="Your Email" type='email' style={{ marginBottom: '20px' }} value={email} onChange={(e) => setEmail(e.target.value)} />
                  <Input placeholder="Your Company/Institution" style={{ marginBottom: '20px' }} optional value={company} onChange={(e) => setCompany(e.target.value)} />
                  <div className="three-column">
                    <CountryDropdown value={country} onChange={(value) => {
                      setCountry(value)
                    }} />
                    <StateDropdown value={state} country={country} placeholder={"State"} onChange={(value) => {
                      setState(value)
                    }} />
                    <select
                      data-kt-select2='true'
                      data-placeholder='City'
                      data-allow-clear='true'
                      data-kt-user-table-filter='two-step'
                      data-hide-search='true'
                      value={city}
                      onChange={(e) => {
                        setCity(e.target.value)
                      }}
                      placeholder='City'
                    >
                      <option disabled selected>City </option>
                      {cities && cities.length && cities?.map((city) => {
                        return <option key={city} value={city}>{city}</option>
                      })}
                    </select>
                  </div>
                  <Input style={{ marginBottom: '20px', pointerEvents: 'none' }} value={topic} onChange={(e) => setTopic(e.target.value)} />
                  <textarea
                    style={{ resize: "none", borderRadius: "8px" }}
                    placeholder='Please describe your service needs.'
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                  ></textarea>
                </div>
                <button class="btn" style={{ float: 'right' }}
                  disabled={!firstName || !lastName || !email || !country || !state || !city || !topic || !description}
                  onClick={sendContact}
                >Submit</button>
              </div>
            </div>
          </div>
      </div>
      </Wrapper>
      <Footer />
    </Layout>
  )
}

export default Index