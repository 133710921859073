import styled from "styled-components";
import { toAbsoluteUrl } from "../../../utils/assetHelper";

export const AboutHeader = styled.div`
h1{
    font-size: 48px;
    font-weight: 800;
    margin-bottom: 16px;
    text-align: center;
    color: #fff;

    @media screen and (max-width: 1100px) {
        font-size: 36px;
    }

    span{
        color: var(--primary-color);
    }
}
`

export const Wrapper = styled.div`
margin-top: 75px;
display: grid;
gap: 48px;
margin-bottom: 48px;
.bio-container{
background: url(${toAbsoluteUrl('/media/alexafluor/image1.png')}) no-repeat;
background-size: cover;
background-position: bottom center;
height: 500px;

@media screen and (max-width:1100px) {
background-position: right bottom;
}
}

.subhero-img{
 width:60%;
 margin: 0 auto;

img {
    width: 100%;
    object-fit: cover;
}

@media screen and (max-width:1100px) {
    width: 90%; 
}
}

.primary-container{
    flex-direction: row-reverse;
    padding-left: 10rem;
    height: 673px;
    @media screen and (max-width:1100px) {
        flex-direction: column;
        padding: 60px 1.2rem;
        width: 90vw;
        height: max-content;
    }
.left-container{
    height:max-content;
    max-height: 100%;

    h4{
        font-size: 24px;
    }

    p{
    font-size: 20px;
    }
}


}

.graphs-container{
    width: 98%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: rgba(237, 237, 237, 0.20);
    padding: 60px 32px;
    border-radius: 20px;

    @media screen and (max-width:1100px) {
        padding: 60px 1.2rem;
        
    }

    .graphs-wrapper{
    display: flex;
    flex-direction: column;
    align-items: center;

    @media screen and (max-width:1100px) {
       svg{
        width: 100%;
        height: 200px;
       }
    }

    .hide-line{
        opacity: 0;
        transition: 0.5s all ease-in-out;
    }
    .active-line{
        opacity: 1;
        transition: 0.5s all ease-in-out;
    }

    .button-container{
        display: flex;
        /* grid-template-columns: repeat(8, 1fr); */
        gap: 8px;

        @media screen and (max-width:1100px) {
            width: 100%;
            flex-direction: column;
            grid-template-columns: 1fr;
            button{
                width: 100% !important;
            }
    }

        button{
            width: 140px;
        }

        .blue{
            background: linear-gradient(0deg, rgba(255, 255, 255, 0.78) 0%, rgba(255, 255, 255, 0.78) 100%), #09F;
            color: #09F;
        }
        .active-blue{
            background: #0099FF;
        }

        .green{
            background: linear-gradient(0deg, rgba(255, 255, 255, 0.80) 0%, rgba(255, 255, 255, 0.80) 100%), #33C25E;
            color: #33C25E;
        }

        .active-green{
            background: #33C25E;
        }

        .yellow{    
        background: linear-gradient(0deg, rgba(255, 255, 255, 0.88) 0%, rgba(255, 255, 255, 0.88) 100%), #FFC224;
        color: #FFC224;
        }

        .active-yellow{
            background: #FFC224;
        }

        .orange{
        background: linear-gradient(0deg, rgba(255, 255, 255, 0.82) 0%, rgba(255, 255, 255, 0.82) 100%), #FF8D24;
        color: #FF8D24;
        }

        .active-orange{
            background: #FF8D24;
        }

        .coral{
        background: linear-gradient(0deg, rgba(255, 255, 255, 0.80) 0%, rgba(255, 255, 255, 0.80) 100%), #FC5555;
        color: #FC5555;
        }

        .active-coral{
            background: #FC5555;
        }

        .pink{
        background: linear-gradient(0deg, rgba(255, 255, 255, 0.78) 0%, rgba(255, 255, 255, 0.78) 100%), #D94949;
        color: #D94949;
        }

        .active-pink{
            background: #D94949;
        }

        .red{
            background: linear-gradient(0deg, rgba(255, 255, 255, 0.76) 0%, rgba(255, 255, 255, 0.76) 100%), #B23C3C;
            color: #B23C3C;
        }

        .active-red{
            background: #B23C3C;
        }

        .dark-red{
            background: linear-gradient(0deg, rgba(255, 255, 255, 0.72) 0%, rgba(255, 255, 255, 0.72) 100%), #993434;
            color: #993434;
        }

        .active-dark-red{
            background: #993434;
        }

        .active{
            color:#fff;
            width: 200px;
        }

    }
}
}
`
export const Content = styled.div`
       display: grid;
       gap: 48px;
@media screen and (max-width: 1100px) {
       width: 98vw;
       margin: 0 auto;
    }
@media screen and (min-width: 2160px) {
      padding: 0 25rem;  
    }
`

export const CardContainer = styled.div`
background: ${props => props.background ? props.background : props.gradientBg ? 'linear-gradient(102deg, rgba(63, 27, 208, 0.05) 4.47%, rgba(215, 76, 16, 0.05) 54.56%)' : "#F1EEFC"};
color: ${props => props.textColor || "#1E1E1E"};
display: flex;
flex-direction: column;
justify-content: ${props => props.justifyContent || "center"};
align-items: ${props => props.alignItems || "center"};
padding: ${props => props.padding || "32px"};
border-radius: ${props => props.radius || "20px"};
width: ${props => props.width || "100%"};
gap: ${props => props.gap || 0};
margin: 0 auto;

@media screen and (max-width: 1100px) {
    padding: 16px;
    width: 100%;

    img {
        width: 50% !important;
         height: 100% !important;
         object-fit: contain;
        }
}

@media screen and (max-width: 490px) {
    img {
        width: 100% !important;
         height: 100% !important;
        }
}

@media screen and (min-width: 2160px) {
    margin: 0;
}

h2 {
    font-size: 36px;
    font-weight: 700;
    line-height: 45px;
    text-align: ${props => props.textAlignH2 || "center"};
    margin-bottom: ${props => props.mb_h2 || "0"};

    span {
        background:linear-gradient(227deg, #DC4405 0%, #FF8D24 85.6%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }

    @media screen and (max-width: 1100px) {
        font-size: 24px;  
    }
}

h3 {
    font-size: 24px;
    font-weight: 600;
    line-height: 28px;
    text-align: ${props => props.textAlignH3 || "center"};
    margin-bottom: ${props => props.mb_h3 || "0"};
    span{
        color: ${props => props.color || '#1e1e1e'}
    }
    @media screen and (max-width: 1100px) {
    font-size: 20px;
    text-align: center;
    }
}

.gradient-text{
        span {
        background:linear-gradient(227deg, #DC4405 0%, #FF8D24 85.6%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        }
}

h4 {
font-weight: ${props => props.fontWeightH4 || 500};
font-size: 20px;
line-height: 28px;
text-align: ${props => props.textAlignH4 || "center"};
margin-bottom: ${props => props.mb_h4 || "0"};

span{
font-weight: 600;
}

@media screen and (max-width: 1100px) {
    text-align: center;
    }
}

h5 {
font-weight: ${props => props.fontWeightH5 || "500"};
font-size: 16px;
line-height: 24px;
text-align: ${props => props.textAlignH5 || "left"};
margin-bottom: ${props => props.mb_h5 || "0"};
}

ul {
list-style: disc;
display: flex;
flex-direction: ${props => props.listDirection || 'column'};
gap: ${props => props.listGap || 0};
@media screen and (max-width: 1100px) {
    padding: 0 16px;
}

li{
font-size: 20px;
font-weight: 600;
span {
    background:linear-gradient(227deg, #DC4405 0%, #FF8D24 85.6%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
}

.list::marker{
    color: #FF8D24;
}
}

p {
font-weight: 400;
font-size: ${props => props.fontSizeP || "20px"};
line-height: 28px;
text-align: ${props => props.textAlignP || "center"};
margin: 0 auto;
margin-bottom: ${props => props.mb_p || "0"};
width: ${props => props.widthP || "100%"};

@media screen and (max-width: 1100px) {
    width: 100%;
}
span {
    font-weight: 700;
}
.gradient{
    background:linear-gradient(227deg, #DC4405 0%, #FF8D24 85.6%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
}

small {
font-size: 12px;
font-weight: 400;
line-height: 24px;
text-align: ${props => props.textAlignSmall || "left"};
margin-bottom: ${props => props.mb_small || "0"};

span {
    font-weight: 700;
}
}

.flex-wrapper {
    display: flex;
    flex-direction: ${props => props.flexDirection || "column"};
    gap: ${props => props.wrapperGap || "5px"};
    align-items: ${props => props.alignItems || "start"};
    justify-content: ${props => props.justifyContent || "start"};
    margin-bottom: ${props => props.mbWrapper || "0"};
    @media screen and (max-width: 1100px) {
    padding: 0 16px;
    flex-direction: column;
    }
}
`

export const HeadingContainer = styled.h2`
font-size: 36px;
font-weight: 600;
line-height: 45px;
text-align: center;
width: ${props => props.width || "100%"};;
margin: 0 auto;

@media screen and (max-width: 1100px) {
    font-size: 24px;
}
span {
    background:linear-gradient(227deg, #DC4405 0%, #FF8D24 85.6%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
`

export const WrapperSmallGap = styled.div`
    display: flex;
    flex-direction: ${props => props.flexDirection || "column"};
    gap: ${props => props.gap || "24px"};
    align-items: ${props => props.alignItems || "start"};
    justify-content: ${props => props.justifyContent || "start"};
    margin-bottom: ${props => props.mbWrapper || "0"};
    width: ${props => props.width || "100%"};
    margin: 0 auto;
    margin-bottom: ${props => props.marginBottom || "0"};

    @media screen and (max-width: 1100px) {
       flex-direction: column;
       width: 100%;
    }

p {
    text-align: ${props => props.textAlignP || "center"};

    span {
        font-weight: 700;
    }
}

img {
    width: ${props => props.imgWidth || "55%"};
    object-fit: cover;
    @media screen and (max-width: 1100px) {
        width: 80%; 
    }
}

`

export const ImageContainer = styled.div`
    position: relative;
    width: ${props => props.width || "100%"};
    margin: 16px 0;

    .image-label {
        position: absolute;
        top: ${props => props.imgTop || 0};
        left: ${props => props.imgLeft || 0};
    }

    img{
        width: 100%;
        height: 580px;
        object-fit: inherit;

        @media screen and (max-width: 1100px) {
         height: 100% !important;
         object-fit: contain;
        }
    }

    .video-container{
        position: absolute;
        top: ${props => props.videoTop || 0};
        left: ${props => props.videoLeft || 0};
        right: ${props => props.videoRight || 0};
        bottom: ${props => props.videoBottom || 0};
        width: max-content;
        height: max-content;

        @media screen and (max-width: 1100px) {
        top: ${props => props.videoTopMobile || 0};
        left: ${props => props.videoLeftMobile || 0};
        right: ${props => props.videoRightMobile || 0};
        bottom: ${props => props.videoBottomMobile || 0};
        }
       
        video{
            border: 1px solid #dc4405;
            border-radius: 12px;

            @media screen and (max-width: 1100px) {
                width: 100px;
            }
        }
    }
`