import React, { useContext, useEffect, useState, useRef } from 'react'
import Layout from "../../components/layout"
import "./style.scss"
import { toAbsoluteUrl } from "../../utils/assetHelper"
import { AuthContext } from '../../context/auth'
import { Footer } from "../../components/Footer"
import { getTeamsApi } from '../../api'
import Shapes from "./Shapes/shapes"

const Index = () => {
    const teamRefs = useRef([]);
    const galleryUrl = `${process.env.REACT_APP_GALLERY_URL}300:350`
    const { setTeamModal } = useContext(AuthContext)
    const [teams, setTeams] = useState([])
    const [visibleTeams, setVisibleTeams] = useState([]);


    const getTeamsData = () => {
        getTeamsApi().then((res) => {
            if (res.data) {
                const leadershipTeam = res?.data?.roles?.find(role => role.name === 'Leadership')?.teams?.sort((a, b) => a.order - b.order)?.map((item) => {
                    return {
                        type: 'orange',
                        description: item.bio,
                        ...item
                    }
                })
                const advisorTeam = res.data.roles.find(role => role.name === 'Advisors')?.teams?.sort((a, b) => a.order - b.order)?.map((item) => {
                    return {
                        type: 'gray',
                        description: item.bio,
                        ...item
                    }
                })
                const Team = res.data.roles.find(role => role.name === 'Team')?.teams?.sort((a, b) => a.order - b.order)?.map((item) => {
                    return {
                        type: 'blue',
                        description: item.bio,
                        ...item
                    }
                })
                setTeams([...teams, ...Team, ...advisorTeam, ...leadershipTeam].sort(customSort))
            }
        })
    }

    useEffect(() => {
        getTeamsData()
    }, [])

    const customSort = (a, b) => {
        const order = { orange: 2, blue: 1, gray: 3 };
        return order[a.type] - order[b.type];
    };

  useEffect(() => {
        const observer = new IntersectionObserver((entries) => {
            entries.forEach(entry => {
                const teamName = entry.target.dataset.name;
                if (entry.isIntersecting && !visibleTeams.includes(teamName)) {
                    console.log(teamName)
                    setVisibleTeams((prev) => [...prev, teamName]);
                }
            });
        }, {
            threshold: 0.8,
        });
        
        teamRefs.current.forEach(ref => {
            observer.observe(ref);
        });

        return () => {
            teamRefs.current.forEach(ref => {
                if (ref) observer.unobserve(ref);
            });
            observer.disconnect();
        };
    
    }, [teams]);


    return (
        <Layout>
            <div className="container title-container bio-container">
                <span style={{ width: '44px', height: '44px', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: '12px', background: '#fff2e5', color: 'var(--primary-color)', marginBottom: '20px' }}>
                    <i className="fas fa-users"></i>
                </span>
                <div className="team-text">
                    <h1>Our <span className='team-bio-active'>Team</span>, <span className='team-bio-active'>Leadership</span> & <span className='team-bio-active'>Advisors</span></h1>
                </div>
            </div>
            <div className="teams-wrapper">  
                {
                    teams.map((team, index) => {
                        const orangeLast = teams.filter(t => t.type === 'orange')?.length
                        const orangeLastName = teams.filter(t => t.type === 'orange')[orangeLast - 1]?.name
                        const blueLast = teams.filter(t => t.type === 'blue')?.length
                        const blueLastName = teams.filter(t => t.type === 'blue')[blueLast - 1]?.name
                        const grayLast = teams.filter(t => t.type === 'gray')?.length
                        const grayLastName = teams.filter(t => t.type === 'gray')[grayLast - 1]?.name
                        const shouldNoIndex = ['Emin Ozandac', 'Harsh Malpani']?.includes(team?.name);

                        const isVisible = visibleTeams.includes(team.name) || !shouldNoIndex;

                        return (
                        <div 
                            ref={(el) => {teamRefs.current[index] = el }}
                            data-name={team.name} 
                            {...(shouldNoIndex && { 'data-nosnippet': '' })}
                            className={`team ${team.type}${(orangeLastName === team.name) && orangeLast % 2 !== 0 ? 'blue-full' : ''}${(blueLastName === team.name) && blueLast % 2 !== 0 ? 'blue-full' : ''}${(grayLastName === team.name) && grayLast % 2 !== 0 ? 'blue-full' : ''}`} key={index + team.name}>
                          {isVisible &&
                            <>
                            <div className="team-img">
                                {team.image_layout ? <Shapes key={team.id} shapeType={team.image_layout} style={{ clipPath: `url(#svgPath-${team.image_layout}-text)`, backgroundImage: `url("${galleryUrl}:${team.image?.split('.com/')[1]}")` }} selectedPhoto={team.image} />
                                    :
                                    <img src={team.image ? galleryUrl + team.image : `${toAbsoluteUrl('/media/teams/blank.png')}`} alt="" style={{ width: '180px', height: '180px' }} />

                                }
                            </div>
                            <div className="team-text" style={{width:'100%'}}>
                                <h3>{team.name}</h3>
                                <h5 style={{ marginBottom: 0 }}>{team.position_1}</h5>
                                <h5>{team.position_2}</h5>
                                <p>{team?.description?.substring(0, 310)}{team?.description ? team?.description?.length > 310 && '...' : ''}</p>
                                <button style={{ float: 'right', marginTop: '12px', color: 'var(--primary-color)', fontWeight: 500, fontSize: '16px' }}
                                    onClick={() => setTeamModal({
                                        isOpen: true,
                                        team
                                    })}
                                >Read More</button>
                            </div>
                            </>
                            }
                        </div>
                )})
                }
            </div>
            <Footer />
        </Layout>
    )
}

export default Index
  