import React, { useState } from 'react'
import Layout from '../../../components/layout'
import { Wrapper, AboutHeader, WrapperSmallGap, HeadingContainer, CardContainer, Content, ImageContainer } from './style'
import { toAbsoluteUrl } from '../../../utils/assetHelper'
import Graph from './graph'
import { Footer } from '../../../components/Footer'

const Index = () => {
    const [dyeBtns, setDyebtns] = useState()

    return (
        <Layout>
            <Wrapper>
                <div className="container title-container bio-container">
                    <AboutHeader>
                        <h1>Identifyn™ SRM Alexa Fluor™</h1>
                    </AboutHeader>
                </div>
                <div className='subhero-img'>
                    <img src={toAbsoluteUrl("/media/services/header1.png")} alt="header1" />
                </div>
                <Content>
                    <WrapperSmallGap alignItems='center' width='90%'>
                        <HeadingContainer>
                            <span>Discover the Unparalleled Imaging Benefits of Identifyn’s™ SRM Alexa Fluor™ Secondary Antibodies, made possible by our Innovative Proprietary Conjugation Method</span>
                        </HeadingContainer>
                        <HeadingContainer>
                            <span>Experience Clarity and Precision in Your Research Like Never Before!</span>
                        </HeadingContainer>
                    </WrapperSmallGap>
                    <div className="graphs-container">
                        <HeadingContainer width='80%' style={{ marginBottom: '60px' }}>Identifyn™ SRM Alexa Fluor™ Cover the Full Spectrum from 405 nm to 750 nm</HeadingContainer>
                        <div className="graphs-wrapper">
                            <Graph data={dyeBtns} />
                            <div className="button-container">
                                <button className={`btn blue ${dyeBtns === 405 && 'active-blue active'}`} onClick={() => setDyebtns(405)}>405</button>
                                <button className={`btn green ${dyeBtns === 488 && 'active-green active'}`} onClick={() => setDyebtns(488)}>488</button>
                                <button className={`btn yellow ${dyeBtns === 532 && 'active-yellow active'}`} onClick={() => setDyebtns(532)}>532</button>
                                <button className={`btn orange ${dyeBtns === 555 && 'active-orange active'}`} onClick={() => setDyebtns(555)}>555</button>
                                <button className={`btn coral ${dyeBtns === 594 && 'active-coral active'}`} onClick={() => setDyebtns(594)}>594</button>
                                <button className={`btn pink ${dyeBtns === 647 && 'active-pink active'}`} onClick={() => setDyebtns(647)}>647</button>
                                <button className={`btn red ${dyeBtns === 680 && 'active-red active'}`} onClick={() => setDyebtns(680)}>680</button>
                                <button className={`btn dark-red ${dyeBtns === 750 && 'active-dark-red active'}`} onClick={() => setDyebtns(750)}>750</button>
                            </div>
                        </div>
                    </div>
                    <CardContainer gradientBg='true' mb_h2='40px' width='98%'>
                        <h2>Identifyn™ Offers Whole IgG (H+L), F(ab')₂, IgG Subclasses and Fc Specific Selections Across a Variety of Species</h2>
                        <img src={toAbsoluteUrl('/media/alexafluor/image2.png')} style={{ width: '900px', height:'400px' }} />
                    </CardContainer>
                    <WrapperSmallGap alignItems='center' width='90%'>
                        <HeadingContainer>
                            <span>Identifyn’s™ SRM Alexa Fluor™ Secondary Antibodies</span>
                        </HeadingContainer>
                        <HeadingContainer>
                            <span>Higher Conjugation Ratios Demonstrate Greatly Improved Imaging Capabilities for both Conventional and Super-Resolution Applications</span>
                        </HeadingContainer>
                    </WrapperSmallGap>
                    <WrapperSmallGap>
                    <WrapperSmallGap width='98%' flexDirection='row'>
                        <CardContainer color="#FF8D24" background="rgba(237, 237, 237, 60%)" flexDirection='row' alignItems='center' wrapperGap='16px' textAlignH4='left' fontWeightH4='400'>
                            <h3><span>Rabbit anti-Human γH2Ax Recombinant Monoclonal - Identifyn™ SRM Alexa Fluor™ 488, IgG</span></h3>
                            <div className="flex-wrapper" style={{margin:'16px 0'}}>
                                <h4><span>Laser = 532nm
                                    Emission Filter = 572±28nm</span>
                                    &nbsp;Detector = APD
                                    <span> Intensity = L1 - Lowest Laser Power Available in this Application</span></h4>
                                <img src={toAbsoluteUrl("/media/alexafluor/image3.png")} alt="Rabbit anti-Human γH2Ax Recombinant Monoclonal" style={{ width: '45%', borderRadius: '12px'}} />
                            </div>
                            <h3 style={{marginBottom: '2px'}}>Immunofluorescent Western Blots</h3>
                            <h3>Azure Sapphire FL</h3>
                        </CardContainer>
                        <CardContainer color="#FC5555" background="rgba(237, 237, 237, 60%)">
                            <h3><span>Rabbit anti-Human γH2Ax Recombinant Monoclonal - Identifyn™ SRM Alexa Fluor™ 555, IgG</span></h3>
                            <img src={toAbsoluteUrl("/media/alexafluor/image4.png")} alt="Rabbit anti-Human γH2Ax Recombinant Monoclonal" style={{ width: '100%', borderRadius: '12px'}} />
                            <h3 style={{marginBottom: '2px'}}>Widefield Microscopy</h3>
                            <h3>ZEISS Axioscope (sCMOS)</h3>
                        </CardContainer>
                    </WrapperSmallGap>
                    <WrapperSmallGap width='98%' flexDirection='row'>
                        <CardContainer color="#a24646" background="rgba(237, 237, 237, 60%)" flexDirection='row' alignItems='center' wrapperGap='16px' textAlignH4='left'>
                            <h3><span>Identifyn™ SRM Alexa Fluor™ 750 Affinity Purified Goat Anti-Rat, IgG (H + L)</span></h3>
                            <img src={toAbsoluteUrl("/media/alexafluor/image5.png")} alt="Rabbit anti-Human γH2Ax Recombinant Monoclonal" style={{ height: '300px', objectFit:'cover', borderRadius: '12px', margin: '16px 0' }} />
                            <h3 style={{marginBottom: '2px'}}>Widefield SIM Microscopy</h3>
                            <h3>ZEISS Apotome (sCMOS)</h3>
                        </CardContainer>
                        <CardContainer color="#33c25e" background="rgba(237, 237, 237, 60%)">
                            <h3><span>Identifyn™ SRM Alexa Fluor™ 488 Affinity Goat  Anti-Rabbit IgG (H + L)</span></h3>
                            <img src={toAbsoluteUrl("/media/alexafluor/image6.png")} alt="Rabbit anti-Human γH2Ax Recombinant Monoclonal" style={{ height: '300px', objectFit:'cover', borderRadius: '12px', margin: '16px 0' }} />
                            <h3 style={{marginBottom: '2px'}}>Confocal Microscopy</h3>
                            <h3>ZEISS LSM900</h3>
                        </CardContainer>
                    </WrapperSmallGap>
                    <WrapperSmallGap width='98%' flexDirection='row'>
                        <CardContainer color="#D7A31D" background="rgba(237, 237, 237, 60%)" flexDirection='row' alignItems='center' wrapperGap='16px' textAlignH4='left'>
                            <h3><span>Identifyn™ SRM Alexa Fluor™ 532 Affinity Purified Goat Anti-Mouse F(ab’)₂ (H + L)</span></h3>
                            <img src={toAbsoluteUrl("/media/alexafluor/image7.png")} alt="Rabbit anti-Human γH2Ax Recombinant Monoclonal" style={{ width: '80%', borderRadius: '12px' }} />
                            <h3 style={{marginBottom: '2px'}}>Super Resolution AiryScan Microscopy</h3>
                            <h3>ZEISS LSM980</h3>
                        </CardContainer>
                        <CardContainer color="#FF8D24" background="rgba(237, 237, 237, 60%)">
                            <h3><span>Identifyn™ SRM Alexa Fluor™ 555 Affinity Purified Donkey Anti-Human IgG (H + L)</span></h3>
                            <img src={toAbsoluteUrl("/media/alexafluor/image8.png")} alt="Rabbit anti-Human γH2Ax Recombinant Monoclonal" style={{ width: '80%', borderRadius: '12px' }} />
                            <h3 style={{marginBottom: '2px'}}>Super Resolution SIM</h3>
                            <h3>ZEISS Elyra 5</h3>
                        </CardContainer>
                    </WrapperSmallGap>
                    <WrapperSmallGap width='98%' flexDirection='row'>
                        <CardContainer color="#FF8D24" background="rgba(237, 237, 237, 60%)" flexDirection='row' alignItems='center' wrapperGap='16px' textAlignH4='left'>
                            <h3><span>Identifyn™ SRM Alexa Fluor™ 555 Affinity Purified Goat Anti-Mouse F(ab’)₂ (H + L)</span></h3>
                            <img src={toAbsoluteUrl("/media/alexafluor/image9.png")} alt="Rabbit anti-Human γH2Ax Recombinant Monoclonal" style={{ width: '80%', borderRadius: '12px', margin: '16px 0' }} />
                            <h3 style={{marginBottom: '2px'}}>Super Resolution SIM²</h3>
                            <h3>ZEISS Elyra 7</h3>
                        </CardContainer>
                        <CardContainer background="rgba(237, 237, 237, 60%)" listGap='5px' textAlignH3='left' style={{ height: '100%'}}>
                            <WrapperSmallGap gap='48px' marginBottom='48px'>
                            <h3 className='gradient-text'><span>Identifyn™ SRM Alexa Fluors™ allow for Imaging at Very Low Powers Resulting in:</span></h3>
                            <ul>
                            <li className='list'><span>Reduced phototoxicity and improved live cell imaging capabilities</span></li>
                            <li className='list'><span>Decreased optical cross talk and autofluorescence</span></li>
                            <li className='list'><span>No photo bleaching provides fluorescent stability over extended acquisition times and time courses</span></li>
                            </ul>
                            </WrapperSmallGap>
                            <h3 className='gradient-text' style={{ marginTop:'auto'}}><span>Experience unparalleled resolution with Identifyn™ SRM Alexa Fluor™ F(ab’)₂ Secondary Antibodies, Advancing your research across spectrum and speciation like never before!</span></h3>
                        </CardContainer>
                    </WrapperSmallGap>
                    </WrapperSmallGap>
                    <WrapperSmallGap>
                    <CardContainer color="#33c25e" background="rgba(237, 237, 237, 60%)" width='98%'>
                        <h3><span>Identifyn™ SRM Alexa Fluor™ 488 Affinity Purified Donkey Anti-Mouse, F(ab’)₂ (H + L)</span></h3>
                        <ImageContainer videoTop='30px' videoLeft='auto' videoRight='30px' videoTopMobile='5px' videoLeftMobile='5px' >
                        <img src={toAbsoluteUrl('/media/alexafluor/image10.png')} alt='' style={{ width: '100%', borderRadius:'12px'}} />
                        <div className="video-container">
                            <video src={toAbsoluteUrl("/media/alexafluor/488.mp4")} controls autoPlay loop muted playsInline></video>
                        </div>
                        </ImageContainer>
                        <h3>Super Resolution STORM Bruker Vutara VXL</h3>
                    </CardContainer>
                    <CardContainer color="#b23c3c" background="rgba(237, 237, 237, 60%)" width='98%'>
                        <h3><span>Identifyn™ SRM Alexa Fluor™ 680 Affinity Purified Goat Anti-Rabbit, F(ab’)₂ (H + L)</span></h3>
                        <ImageContainer videoTop='auto' videoLeft='30px' videoBottom='30px' videoTopMobile='auto' videoBottomMobile='5px' videoLeftMobile='5px'>
                        <img src={toAbsoluteUrl('/media/alexafluor/image11.png')} alt='' style={{borderRadius:'12px'}} />
                        <div className="video-container">
                            <video src={toAbsoluteUrl("/media/alexafluor/680.mp4")} controls autoPlay loop muted playsInline></video>
                        </div>
                        </ImageContainer>
                        <h3>Super Resolution STORM Bruker Vutara VXL</h3>
                    </CardContainer>
                    <CardContainer color="#FF8D24" background="rgba(237, 237, 237, 60%)" width='98%' mb_h2='28px' listGap='5px'>
                        <HeadingContainer width='90%'><span>Identifyn™ SRM Alexa Fluor™ provides a wider range for STORM applications at 488, 532, 555, 594, 647, and 680nm excelling in single molecule localization</span></HeadingContainer>
                        <HeadingContainer><span>Proprietary conjugation chemistry provides better blinking statistics</span></HeadingContainer>
                        <ul>
                            <li className='list'><span>Less photobleaching and longer acquisition</span></li>
                            <li className='list'><span>Higher localization data points</span></li>
                            <li className='list'><span>Better resolution</span></li>
                            <li className='list'><span>Identifyn™ SRM Alexa Fluor™ F(ab’)₂ provide improved resolution</span></li>
                        </ul>
                    </CardContainer>
                    </WrapperSmallGap>
                </Content>
            </Wrapper>
            <Footer />
        </Layout>
    )
}

export default Index