import React, { useContext, useState, useEffect } from 'react'
import axios from 'axios'
import Layout from "../../components/layout"
import { ContactWrapper, ContactCardContainer, ContactImageContainer } from './style'
import { toAbsoluteUrl } from '../../utils/assetHelper'
import Input from "../../components/Input"
import { CountryDropdown, StateDropdown } from '../../components/DropDowns'
import Dropdown from "../../components/Dropdown"
import { contactApi } from '../../api'
import { AuthContext } from '../../context/auth'
import { Footer } from "../../components/Footer"

const Index = () => {
    const { setNotification, setIsLoading } = useContext(AuthContext)
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [company, setCompany] = useState('');
    const [country, setCountry] = useState('United States');
    const [state, setState] = useState('');
    const [city, setCity] = useState('');
    const [topic, setTopic] = useState();
    const [description, setDescription] = useState('');
    const [cities, setCities] = useState([]);

    const data = (new URL(document.location).searchParams)
    const support = data.get('support')

    useEffect(() => {
        if (support) {
            setTopic(support)
        }
    }, [support])



    const sendContact = async () => {
        setIsLoading(true)
        if (email && RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/).test(email)) {
            const data = {
                first_name: firstName,
                last_name: lastName,
                email,
                company,
                country,
                state,
                city,
                topic,
                message: description,
                email_to: 'contact@identifyn.com'
            }
            const response = await contactApi(data)
            if (response?.status === 200) {
                setFirstName('')
                setLastName('')
                setEmail('')
                setCompany('')
                setCountry('United States')
                setState('')
                setCity('')
                setTopic('')
                setDescription('')
                setIsLoading(false)
                setNotification({
                    type: 'success',
                    message: 'Message sent successfully!'
                })
            } else {
                setIsLoading(false)
                setNotification({
                    type: 'red',
                    message: 'Something went wrong, please try again!'
                })
            }
        } else {
            setIsLoading(false)
            setNotification({
                type: 'red',
                message: 'Please enter a valid Email Address'
            })
        }
    }

    useEffect(() => {
        if (state === undefined) return
        if (state) {
            axios.post('https://countriesnow.space/api/v0.1/countries/state/cities', {
                country: country,
                state: state
            }).then((res) => {
                setCities(res.data.data)
            }).catch((err) => {
                console.error(err)
            })
        }
    }, [state])





    return (
        <Layout>
            <ContactImageContainer>
            <div className="bruker-image-grid">
                <img src={toAbsoluteUrl("/media/bruker/bruker7.jpg")} alt="" />
                <div className='image-grid-column'>
                    <img src={toAbsoluteUrl("/media/bruker/bruker8.gif")} alt="" />
                    <img src={toAbsoluteUrl("/media/bruker/bruker9.gif")} alt="" />
                </div>
            </div>
            </ContactImageContainer>
            <ContactWrapper>
                <div className="left-container">
                    <div className="solutions-text">
                        <h2 style={{ width: '100%' }}>Contact <span>Us</span></h2>
                        <p>Please contact Identifyn™ using this form. </p>
                        <p>Select topic from dropdown and briefly describe how we can help.
                        </p>
                    </div>
                </div>
                <div className="right-container">
                    <div className="form-container">
                        <div className="form-wrapper">
                            <div
                                style={{ display: "block", width: "100%", marginTop: "20px", marginBottom: '20px' }}
                            >
                                <div className="two-column">
                                    <Input placeholder="Your First Name" value={firstName} onChange={(e) => setFirstName(e.target.value)} />
                                    <Input placeholder="Your Last Name" value={lastName} onChange={(e) => setLastName(e.target.value)} />
                                </div>
                                <Input placeholder="Your Email" type='email' style={{ marginBottom: '20px' }} value={email} onChange={(e) => setEmail(e.target.value)} />
                                <Input placeholder="Your Company/Institution" style={{ marginBottom: '20px' }} optional value={company} onChange={(e) => setCompany(e.target.value)} />
                                <div className="three-column">
                                    <CountryDropdown value={country} onChange={(value) => {
                                        setCountry(value)
                                    }} />
                                    <StateDropdown value={state} country={country} placeholder={"State"} onChange={(value) => {
                                        setState(value)
                                    }} />
                                    <select
                                        data-kt-select2='true'
                                        data-placeholder='City'
                                        data-allow-clear='true'
                                        data-kt-user-table-filter='two-step'
                                        data-hide-search='true'
                                        value={city}
                                        onChange={(e) => {
                                            setCity(e.target.value)
                                        }}
                                        placeholder='City'
                                    >
                                        <option disabled selected>City </option>
                                        {cities && cities.length && cities?.map((city) => {
                                            return <option key={city} value={city}>{city}</option>
                                        })}
                                    </select>
                                </div>
                                <Dropdown
                                    value={topic}
                                    onChange={(e) => {
                                        setTopic(e)
                                    }}
                                    style={{ marginBottom: '20px', width: '100%' }}
                                    label={'Select Topic'}
                                    optionType='array'
                                    options={
                                        [
                                            "Purchase Identifyn™ Reagents",
                                            "Microscopy Support",
                                            "Protein Chemistry Support",
                                            "Primary Antibody",
                                            "Primary Fluorescent Conjugates",
                                            "Secondary Fluorescent Conjugates",
                                            "Assays Inquiry",
                                            "Service Inquiry",
                                            "General Inquiry"
                                        ]
                                    }
                                />
                                {/* <select
                                    data-placeholder='Topic'
                                    value={topic}
                                    onChange={(e) => {
                                        setTopic(e.target.value)
                                    }}
                                    placeholder='Select Topic'
                                    style={{ marginBottom: '20px' }}
                                >
                                    <option disabled selected>Select Topic </option>
                                    <option value={"Microscopy Support"}>Microscopy Support</option>
                                    <option value={"Protein Chemistry Support"}>Protein Chemistry Support</option>
                                    <option value={"Primary Antibody"}>Primary Antibody</option>
                                    <option value={"Primary Fluorescent Conjugates"}>Primary Fluorescent Conjugates</option>
                                    <option value={"Secondary Fluorescent Conjugates"}>Secondary Fluorescent Conjugates</option>
                                    <option value={"Reagents & Glass"}>Reagents & Glass</option>
                                    <option value={"STORM Buffers"}>STORM Buffers</option>
                                    <option value={"Kits"}>Kits</option>
                                    <option value={"Account Support"}>Account Support</option>
                                    <option value={"Order Support"}>Order Support</option>
                                    <option value={"General Inquiry"}>General Inquiry</option>
                                    <option value={"Carrers"}>Careers</option>
                                </select> */}
                                <textarea
                                    style={{ resize: "none", borderRadius: "8px" }}
                                    placeholder='Write your message here!'
                                    value={description}
                                    onChange={(e) => setDescription(e.target.value)}
                                ></textarea>
                            </div>
                            <button class="btn" style={{ float: 'right' }}
                                disabled={!firstName || !lastName || !email || !country || !state || !city || !topic || !description}
                                onClick={sendContact}
                            >Get In Touch!</button>
                        </div>
                    </div>
                </div>
            </ContactWrapper>
            <ContactCardContainer>
                <div className="card">
                    <span style={{ width: '44px', height: '44px', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: '12px', background: '#fff2e5', color: 'var(--primary-color)' }}>
                        <i class="far fa-mobile"></i>
                    </span>
                    <h5>Phone Number</h5>
                    <h4>941.529.0647</h4>
                </div>
                <div className="card">
                    <span style={{ width: '44px', height: '44px', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: '12px', background: '#fff2e5', color: 'var(--primary-color)' }}>
                        <i class="fal fa-microscope"></i>
                    </span>
                    <h5>Laboratory Address </h5>
                    <h4>2055 Wood Street, Suite 102 Sarasota, FL 34237</h4>
                </div>
                <div className="card">
                    <span style={{ width: '44px', height: '44px', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: '12px', background: '#fff2e5', color: 'var(--primary-color)' }}>
                        <i class="far fa-building"></i>
                    </span>
                    <h5>Corporate Address</h5>
                    <h4>2055 Wood Street, Suite 206 Sarasota, FL 34237</h4>
                </div>
            </ContactCardContainer>
            <Footer />
        </Layout>
    )
}

export default Index