import React, { useContext, useEffect, useState } from 'react'
import { DrawerWrapper } from './style'
import { toAbsoluteUrl } from '../../../utils/assetHelper'
import MethodologyModal from '../../modals/MethodologyModal'
import { useNavigate, Link } from 'react-router-dom';
import { Tooltip as ReactTooltip } from "react-tooltip";
import ReactPrint from "react-to-print";
import { AuthContext } from '../../../context/auth';
import { createCartApi, addItemsToCartApi, getCoaApi } from '../../../api'
import { getLocalStorage, setLocalStorage } from "../../../helpers/localStorage";
import { isVideoFile, getMp4Url, isGif } from '../../../utils/videoHelper';
import { isValidURL } from '../../../utils/urlHelper';
import { printPdf } from '../../../utils/print';
import { crossReactivityMap } from '../../../utils/crossReactivity';
import axios from 'axios';

const Index = ({ selectedProduct, setSelectedProduct, coARef }) => {
    const { setIsLoading, setCartData, setOpenItemPreviewModal, isLoading, setTempDisable, tempDisable, setNotification } = useContext(AuthContext)
    const galleryUrl = `${process.env.REACT_APP_GALLERY_URL}250:250`
    const cartId = getLocalStorage('cartId')
    const navigate = useNavigate()
    const [openMethodologyModal, setOpenMethodologyModal] = useState(false)
    const [productTab, setProductTab] = useState("primary")
    const [modalName, setModalName] = useState("")
    const [images, setImages] = useState([])
    const [imageSlideIndex, setImageSlideIndex] = useState(0)

    useEffect(() => {
        if (selectedProduct?.mid_code.search("PA") >= 0) {
            setProductTab("primary")
        } else if (selectedProduct?.mid_code.search("DC") >= 0) {
            setProductTab("alexaflour")
        } else {
            setProductTab("")
        }

        if (selectedProduct?.metadata?.images) {
            const arr = selectedProduct?.metadata?.images?.filter((item) => item.url !== '')
            const splitArr = Array.from({ length: Math.ceil(arr.length / 4) }, (_, index) =>
                arr.slice(index * 4, index * 4 + 4)
            );
            setImages(splitArr)
        }

    }, [selectedProduct])

    const AddToCart = () => {
        setIsLoading(true)
        if (!cartId) {
            createCartApi().then(res => {
                setLocalStorage('cartId', res.data.cart.id)
                addItemsToCartApi(res.data.cart.id, {
                    variant_id: selectedProduct?.variants[0]?.id,
                    quantity: 1,
                }).then((res) => {
                    if (res && res.data && res.data?.cart) {
                        setCartData(res.data.cart)
                        setNotification({
                            message: 'Added to cart',
                            type: 'green',
                        })
                        setIsLoading(false)
                    } else {
                        setNotification({
                            message: 'Something went wrong',
                            type: 'red',
                        })
                        setIsLoading(false)
                    }
                })
            })
        } else {
            addItemsToCartApi(cartId, {
                variant_id: selectedProduct?.variants[0]?.id,
                quantity: 1,
            }).then((res) => {
                if (res && res.data && res.data?.cart) {
                    setCartData(res.data.cart)
                    setNotification({
                        message: 'Added to cart',
                        type: 'green',
                    })
                    setIsLoading(false)
                } else {
                    setNotification({
                        message: 'Something went wrong',
                        type: 'red',
                    })
                    setIsLoading(false)
                }
            })
        }
    }

    const BuyProduct = () => {
        setIsLoading(true)
        if (!cartId) {
            createCartApi().then(res => {
                setLocalStorage('cartId', res.data.cart.id)
                addItemsToCartApi(res.data.cart.id, {
                    variant_id: selectedProduct?.variants[0]?.id,
                    quantity: 1,
                }).then((res) => {
                    if (res && res.data && res.data?.cart) {
                        setCartData(res.data.cart)
                        setNotification({
                            message: 'Added to cart',
                            type: 'green',
                        })
                        setIsLoading(false)
                        navigate('/checkout')
                    } else {
                        setNotification({
                            message: 'Something went wrong',
                            type: 'red',
                        })
                        setIsLoading(false)
                    }
                })
            })
        } else {
            addItemsToCartApi(cartId, {
                variant_id: selectedProduct?.variants[0]?.id,
                quantity: 1,
            }).then((res) => {
                if (res && res.data && res.data?.cart) {
                    setCartData(res.data.cart)
                    setNotification({
                        message: 'Added to cart',
                        type: 'green',
                    })
                    setIsLoading(false)
                    navigate('/checkout')
                } else {
                    setNotification({
                        message: 'Something went wrong',
                        type: 'red',
                    })
                    setIsLoading(false)
                }
            })
        }
    }

    const openPrintDialog = (pdfUrl) => {
        setIsLoading(true)
        printPdf(pdfUrl)
        setIsLoading(false)
    }

    const printCoa = (id) => {
        setIsLoading(true)
        getCoaApi(id).then((response) => {
        const blob = new Blob([response.data], { type: 'application/pdf' })
        const url = window.URL.createObjectURL(blob)
        setIsLoading(false)
        printPdf(url)
        }).catch(() => {
            setIsLoading(false)
        })
    }

    return (
        <>
            <DrawerWrapper>
                <div className="drawer-modal-content">
                    {productTab && <div className="header">
                        <h2>Anti-Human {selectedProduct?.subtitle}</h2>
                        <div className="icons-container">
                            <i className='fal fa-times' onClick={() => {
                                setOpenItemPreviewModal(false)
                                setSelectedProduct(null)
                            }}></i>
                        </div>
                    </div>
                    }
                    {!productTab && <div className="header">
                        <h2>{selectedProduct?.host} {selectedProduct?.species_reactivity}</h2>
                        <div className="icons-container">
                            <i className='fas fa-times' onClick={() => setOpenItemPreviewModal(false)}></i>
                        </div>
                    </div>
                    }
                    {productTab && <div className="tab-container">
                        <button style={{ borderTopLeftRadius: '12px', borderBottomLeftRadius: '12px', width: '35%' }} className={productTab === "primary" && 'tab-active'} disabled >Primary Antibodies</button>
                        <button style={{ borderTopRightRadius: '12px', borderBottomRightRadius: '12px', width: '65%' }} className={productTab === "alexaflour" && 'tab-active'} disabled>Identifyn™ SRM AlexaFluor™ Conjugates</button>
                    </div>
                    }
                    {productTab === 'alexaflour' && <button className='dye-btn' disabled >Dye: {selectedProduct?.dyes}</button>}
                    {productTab && <div className="items-container">
                        <button style={{ borderTopLeftRadius: '12px', borderBottomLeftRadius: '12px' }} className={selectedProduct?.host + " " + selectedProduct?.clonality === "Mouse Monoclonal" && 'tab-active'} disabled >Mouse Monoclonal</button>
                        <button style={{ borderTopRightRadius: '12px', borderBottomRightRadius: '12px' }} className={selectedProduct?.host + " " + selectedProduct?.clonality === "Mouse Recombinant Monoclonal" && 'tab-active'} disabled>Mouse Recombinant Monoclonal</button>
                        <button style={{ borderTopRightRadius: '12px', borderBottomRightRadius: '12px' }} className={selectedProduct?.host + " " + selectedProduct?.clonality === "Rabbit Polyclonal" && 'tab-active'} disabled>Rabbit Polyclonal</button>
                        <button style={{ borderTopRightRadius: '12px', borderBottomRightRadius: '12px' }} className={selectedProduct?.host + " " + selectedProduct?.clonality === "Rabbit Recombinant Monoclonal" && 'tab-active'} disabled>Rabbit Recombinant Monoclonal</button>
                        <button style={{ borderTopRightRadius: '12px', borderBottomRightRadius: '12px' }} className={selectedProduct?.host + " " + selectedProduct?.clonality === "Rat Monoclonal" && 'tab-active'} disabled>Rat Monoclonal</button>
                    </div>
                    }
                    <div className="item-details-container">
                        <div>
                        <div className="item-detail-header">
                            <div className="left-container" style={{ width: '85%' }}>
                                <h4 style={{ color: 'var(--primary-color)' }}>{selectedProduct?.title}{productTab && `, ${selectedProduct?.metadata?.subclass}`}{selectedProduct?.metadata?.cloneId && ` - (${selectedProduct?.metadata?.cloneId})`}</h4>
                                <h5>Cat ID# - {selectedProduct?.mid_code} {selectedProduct?.material}</h5>
                            </div>
                            <div>
                            {/* <ReactPrint
                                onBeforeGetContent={() => {
                                    setIsLoading(true)
                                }}
                                onAfterPrint={() => {
                                    setIsLoading(false)
                                }}
                                trigger={() => <span className='coa' style={{ cursor: 'pointer', alignSelf: 'flex-start' }}>
                                    CoA</span>}
                                content={() => coARef.current}
                            /> */}
                            <span className='coa' style={{ cursor: 'pointer'}} onClick={() => {
                                printCoa(selectedProduct?.id)
                            }}>
                                CoA
                            </span>
                            {selectedProduct?.metadata?.sds && <span className='coa' style={{ cursor: 'pointer', marginTop: '5px' }} onClick={() => {
                                openPrintDialog(selectedProduct?.metadata?.sds?.url)
                            }}>
                                SDS
                            </span>
                            }
                            </div>
                        </div>
                        {
                            crossReactivityMap?.find((item) =>
                                (item.host === selectedProduct?.host && item.species_reactivity === selectedProduct?.species_reactivity))
                            &&
                            <h5 className='cross_reactivity'>Cross Reactivity:{' '}
                                {
                                    crossReactivityMap?.find((item) =>
                                        (item.host === selectedProduct?.host && item.species_reactivity === selectedProduct?.species_reactivity)
                                    )?.cross_reactivity
                                }
                            </h5>
                        }
                        </div>
                        <div className="item-content">
                            <div className="left-container">
                                {/* {!productTab && <h4>Excitation Wavelength</h4>} */}
                                {/* {!productTab && <h4>Emission Wavelength</h4>} */}
                                {productTab &&
                                    <>
                                        {selectedProduct?.metadata?.uniprotId && <h4>Uniprot ID</h4>}
                                        {selectedProduct?.metadata?.geneId && <h4>Gene ID</h4>}
                                        {selectedProduct?.metadata?.geneSymbol && <h4>Gene Symbol</h4>}
                                        {selectedProduct?.metadata?.geneAliases && <h4>Gene Aliases</h4>}
                                    </>
                                }
                                <h4>Concentration</h4>
                                <h4>Buffer</h4>
                                <h4>pH</h4>
                                <h4>Storage</h4>
                                {productTab !== 'primary' && <h4>Dye Ex/Em</h4>}
                                {productTab !== 'primary' && <h4>Extinction Coefficient</h4>}
                                {productTab !== 'primary' && <h4>Molecular Weight</h4>}
                                {productTab !== 'primary' && <h4>Emission Color</h4>}
                                {/* <h4>Applications</h4> */}
                                {/* <h4>Price</h4> */}
                            </div>
                            <div className="right-container">
                                {/* {!productTab && <h4>{selectedProduct?.excitation_wavelength}</h4>} */}
                                {/* {!productTab && <h4>{selectedProduct?.emission_wavelength}</h4>} */}
                                {productTab &&
                                    <>
                                        {selectedProduct?.metadata?.uniprotId && <h4 className='external_link'><a href={`https://www.uniprot.org/uniprotkb/${selectedProduct?.metadata?.uniprotId}/entry`} target='_blank'>{selectedProduct?.metadata?.uniprotId}</a></h4>}
                                        {selectedProduct?.metadata?.geneId && <h4 className='external_link'><a href={`https://www.ncbi.nlm.nih.gov/gene/${selectedProduct?.metadata?.geneId}`} target='_blank'>{selectedProduct?.metadata?.geneId}</a></h4>}
                                        {selectedProduct?.metadata?.geneSymbol && <h4>{selectedProduct?.metadata?.geneSymbol}</h4>}
                                        {selectedProduct?.metadata?.geneAliases && <h4>{selectedProduct?.metadata?.geneAliases}</h4>}
                                    </>
                                }
                                <h4>{selectedProduct?.concentration ? `${selectedProduct?.concentration} mg/mL` : '-'} </h4>
                                <h4>{selectedProduct?.buffer_molarity ? `${selectedProduct?.buffer_molarity}mM` : '-'} {selectedProduct?.buffer ? selectedProduct?.buffer : '-'}</h4>
                                <h4>{selectedProduct?.ph ? selectedProduct.ph : '-'}</h4>
                                <h4>{selectedProduct?.storage ? selectedProduct?.storage?.map((item) => item + "°C").join(", ") : '-'}</h4>
                                {productTab !== 'primary' && <h4>{selectedProduct?.excitation_wavelength ? `${selectedProduct?.excitation_wavelength} nm` : '-'} / {selectedProduct?.emission_wavelength ? `${selectedProduct?.emission_wavelength} nm` : '-'}</h4>}
                                {productTab !== 'primary' && <h4>{selectedProduct?.extinction_coefficient?.includes(',') ? selectedProduct?.extinction_coefficient : Number(selectedProduct?.extinction_coefficient)?.toLocaleString('en-US')}</h4>}
                                {productTab !== 'primary' && <h4>{selectedProduct?.molecular_weight ? `${selectedProduct?.molecular_weight} Da` : '-'}</h4>}
                                {productTab !== 'primary' && <h4>{selectedProduct?.emission_color ? selectedProduct?.emission_color : '-'}</h4>}
                            </div>
                        </div>
                        <div className="item-content">
                            <div className="left-container" style={{ width: '50%' }}>
                                <h4>Applications</h4>
                            </div>
                            <div className="right-container">
                                <div className="application-container">
                                    {selectedProduct?.metadata?.protein_application?.map((item) => {
                                        return <span onClick={() => {
                                            setModalName(item.name)
                                            setOpenMethodologyModal(true)
                                        }} data-tooltip-id="applications" data-tooltip-content={item.name}>{item.abbr}</span>
                                    })
                                    }
                                    {selectedProduct?.metadata?.microscopy_application?.map((item) => {
                                        return <span onClick={() => {
                                            setModalName(item.name)
                                            setOpenMethodologyModal(true)
                                        }} data-tooltip-id="applications" data-tooltip-content={item.name}>{item.abbr}</span>
                                    })
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="item-content">
                            <div className="left-container">
                                <h4>Price / 50 µL Unit Size</h4>
                            </div>
                            <div className="right-container">
                                <h4>${(selectedProduct?.variants[0]?.prices[0]?.amount / 100)?.toFixed(2)}</h4>
                            </div>
                        </div>
                    </div>
                    <div className="item-image-container">
                        {images && images.length > 0 && <div className="images-container">
                            {images[imageSlideIndex]?.map((item, index) => {
                                return <div className="image" onClick={() => {
                                    setOpenMethodologyModal(true)
                                    setModalName(item.name)
                                }} key={index}>
                                    {isVideoFile(item) ?
                                        <video src={getMp4Url(item.url)} loop muted autoPlay playsInline /> :
                                        isGif(item) ?
                                            <img src={item.url} alt="" /> :
                                            <img src={galleryUrl + `:${item.url?.split('.com/')[1]}` || toAbsoluteUrl('/media/methodologyplaceholder.png')} alt="" />
                                    }
                                    <h6>{item.name}</h6>
                                </div>
                            })}
                        </div>
                            // :
                            // <div className='no-orders'>
                            //     <span className='icon'>
                            //         <i class="far fa-images"></i>
                            //     </span>
                            //     <p>No Images Available.</p>
                            // </div>
                        }
                        {images && images.length > 1 && <div className="buttons-container">
                            <button onClick={() => {
                                if (imageSlideIndex > 0) {
                                    setImageSlideIndex(imageSlideIndex - 1)
                                } else {
                                    setImageSlideIndex(0)
                                }
                            }}><i className='fas fa-chevron-left'></i></button>
                            <div className="dots">
                                {
                                    images.map((item, index) => {
                                        return <span className={imageSlideIndex === index ? "active-dot" : ""} key={index}></span>
                                    })
                                }
                                {/* <span className='active-dot'></span>
                                <span></span>
                                <span></span> */}
                            </div>
                            <button
                                onClick={() => {
                                    if (imageSlideIndex < images.length - 1) {
                                        setImageSlideIndex(imageSlideIndex + 1)
                                    } else {
                                        setImageSlideIndex(images.length - 1)
                                    }
                                }}
                            ><i className='fas fa-chevron-right'></i></button>
                        </div>
                        }
                    </div>
                    {selectedProduct?.metadata?.reference && selectedProduct?.metadata?.reference?.some((item) => isValidURL(item)) &&
                        <div className='references-container'>
                            <h4>References</h4>
                            {
                                selectedProduct?.metadata?.reference?.map((item, index) => {
                                    if (isValidURL(item)) {
                                        return <a href={item.includes("https") ? item : `https://${item}`} target="_blank" key={index}>{item}</a>
                                    }
                                })}
                        </div>
                    }
                    <p className='exclusive-container'>
                        Get Identifyn™ products exclusively through <img src={toAbsoluteUrl('/media/logoicon.png')} />
                    </p>
                    <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                        <a href={selectedProduct?.metadata?.thermofisher_link} target="_blank"><img src={toAbsoluteUrl("/media/thermologo.png")} alt="thermologo" width={'148px'} height={'70px'} /></a>
                    </div>
                    <div className="add-cart-container">
                        <p className='exclusive-container'>
                            Identifyn™ Super Resolution Vetted Premium Research Products are Available through our Preferred Industry Partners
                        </p>
                        {/* <button className='btn add-btn'
                        disabled={isLoading}
                        onClick={() => {
                            tempDisable ? setTempDisable(false) :
                            AddToCart()
                            // setSelectedProtein(false)
                            // navigate('/cart')
                        }}
                    >
                        <i className='fal fa-shopping-cart'></i>
                        Add to Cart</button>
                    <button className='btn buy-btn'
                        disabled={isLoading}
                        onClick={() => {
                           tempDisable ? setTempDisable(false) : BuyProduct()
                        }}
                    >Buy Now</button> */}
                    </div>
                </div>
                <ReactTooltip
                    id="applications"
                    place="top"
                    variant="light"
                />
            </DrawerWrapper>
            {openMethodologyModal && <MethodologyModal setOpenMethodologyModal={setOpenMethodologyModal} selectedProduct={selectedProduct} modalName={modalName} setModalName={setModalName} />}
        </>
    )
}

export default Index