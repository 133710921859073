import styled from "styled-components";
import { toAbsoluteUrl } from "../../utils/assetHelper";
import ContactBg from "../../assets/contactBg1.png";

export const ContactWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-align: center;
    padding: 160px 5rem 60px 5rem;
    background: url(${ContactBg}) no-repeat;
    background-size: cover;
    background-position: center left;
    border-radius: 20px;
    

    @media screen and (max-width: 1100px) {
        flex-direction: column;
        padding: 60px 1.2rem;
        gap: 50px;
        background-position: center bottom;

        .left-container {
            width: 100% !important;
        }

        .right-container {
            display: block;
            width: 100% !important;
        }

    }


    @media screen and (min-width: 2160px) {
        padding-left: 25rem;
        padding-right: 25rem;

    }


    .left-container {
        width: 45%;

        .solutions-text {
            h2 {
                color: var(--white);
                font-size: 48px;
                font-weight: 700;
                margin-bottom: 12px;
                text-align: left;

                span {
                    color: var(--primary-color)
                }

                @media screen and (max-width: 1100px) {
                    text-align: center;
                }

            }

            p {
                color: #fcfcfc;
                margin-top: 2px;
                font-size: 16px;
                line-height: 24px;
                width: 70%;
                text-align: left;

                @media (max-width: 1100px) {
                    width: 100%;
                    text-align: center;
                }
            }
        }
    }

    .right-container {
        width: 55%;

        .form-container {
            width: 100%;
            height: max-content;
            border-radius: 16px;
            background: rgba(252, 252, 252, 0.92);
            backdrop-filter: blur(18px);
            padding: 24px;
            

            @media (max-width: 1100px) {
                width: 100%;
                height: max-content;

                .btn {
                    float: none !important;
                }
            }

            .two-column {
                display: flex;
                justify-content: space-between;
                gap: 20px;
                margin-bottom: 20px;

                @media (max-width: 1000px) {
                    flex-direction: column;
                }
            }

            .three-column {
                display: flex;
                justify-content: space-between;
                gap: 20px;
                margin-bottom: 20px;

                @media screen and (max-width: 1000px) {
                    flex-direction: column;
                }

                .phone-number-div {
                    width: 50%;
                    transform: translateY(-5px);

                    @media screen and (max-width: 1000px) {
                        width: 100%;
                    }
                }

                .two-column {
                    display: flex;
                    width: 50%;
                    justify-content: space-between;
                    gap: 20px;
                    margin-bottom: 0px !important;

                    @media (max-width: 1000px) {
                        flex-direction: column;
                        width: 100%;
                    }
                }
            }

            select {
                border-radius: 12px;
                border: 1px solid #F1EEFC;
                background: #FCFCFC;
                backdrop-filter: blur(19px);
                display: flex;
                height: 48px;
                padding: 10px 12px;
                outline: unset !important;
                border: unset !important;
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 20px;
                color: #28272e;
                width: 100%;

                @media (max-width: 768px) {
                    width: -webkit-fill-available;
                }

                @media (max-width: 1000px) {
                    width: -webkit-fill-available;
                }

                -moz-appearance: none;
                -webkit-appearance: none;
                appearance: none;
                background-image: url(${toAbsoluteUrl("/media/angle-down.png")});
                background-repeat: no-repeat,
                repeat;
                background-position: right 0.7em top 50%,
                0 0;
                background-size: 0.65em auto,
                100%;
            }

            textarea {
                width: 100%;
                height: 90px;
                color: #7d8ca5;
                font-weight: 400;
                padding: 10px 12px;
                outline: unset !important;
                /* overflow: hidden; */
                border-radius: 12px;
                border: 1px solid #F1EEFC;
                background: #FCFCFC;
                backdrop-filter: blur(19px);
                color: #28272E;
                text-overflow: ellipsis;
                /* white-space: nowrap; */

                :focus-visible {
                    outline: unset !important;
                }

                &::placeholder {
                    color: #9a9a9a;
                    font-family: "Inter";
                    font-style: normal;
                    font-size: 14px;
                    line-height: 20px;
                    opacity: 1;
                }

                @media (max-width: 1000px) {
                    height: 140px !important;
                }
            }

        }
    }


`


export const ContactCardContainer = styled.div`
   background: linear-gradient(102deg, rgba(215, 76, 16, 0.08) 34.47%, rgba(63, 27, 208, 0.10) 54.56%);
    display: flex;
    justify-content: center;
    gap: 32px;
    padding: 100px 5rem;


    @media (max-width: 1000px) {
        flex-direction: column;
        padding: 100px 1.2rem;
    }

    @media (min-width: 2160px) {
        padding-left: 25rem;
        padding-right: 25rem;
    }


    .card{
        display: flex;
        flex-direction: column;
        align-items: center;
        gap:10px;
        padding: 24px 36px;
        border-radius: 16px;
        background: #FFF;
        width: 380px;
        min-height: 120px;

@media screen and (max-width:1100px) {
    width: 100%;
}


h4{
color:#1E1E1E;
text-align: center;
font-size: 20px;
font-style: normal;
font-weight: 700;
line-height: 28px
}

h5{
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 24px;
color: #DC4405;
}

    }

`

export const ContactImageContainer = styled.div`
@media screen and (min-width: 500px) {
   display: none;
}
margin-top: 90px;
.bruker-image-grid{
display: flex;
flex-direction: column;
gap: 15px;
padding: 0 16px 20px 16px;

img{
width: 100% !important;
border-radius: 12px !important;
}

.image-grid-column{
display: grid;
gap: 15px;
grid-template-columns: 1fr 1fr;

img{
width: 100% !important;
border-radius: 12px !important;
}

}
}
`