import React, { useState } from 'react'
import Layout from '../../../components/layout'
import { Wrapper, AboutHeader, WrapperSmallGap, HeadingContainer, CardContainer, Content } from './style'
import { toAbsoluteUrl } from '../../../utils/assetHelper'
import { Footer } from '../../../components/Footer'
import Graph from '../AlexFluor/graph'

const Index = () => {
    const [solutionSlider, setSolutionSlider] = useState(0);
    const [dyeBtns, setDyebtns] = useState()
    const Solutions = [
        {
            title: "I - Immunization",
            description: `Identifyn™ ensures host immunization using high-quality whole protein and modified protein immunogens to achieve maximal immune response.`,
        },
        {
            title: "I & S - Isolation and Selection",
            description: `Identifyn™ deploys advanced techniques to isolate and select antigen-specific immune cells to create hybridomas from mice and specific B-cells from Rabbits.`,
        },

        {
            title: "H & R - Hybridoma & Recombination",
            description: `Identifyn™ creates stable hybridoma products for robust monoclonal antibody production and plasmid-mediated transfection for high-quality recombinant antibody production, ensuring an uninterrupted supply of highly specific affinity reagents.`,
        },

        {
            title: "V - Validation",
            description: `At Identifyn, we rigorously validate our primary antibodies across a spectrum of microscopy techniques, from conventional to super-resolution, as well as through fluorescent Western blotting. This comprehensive validation process guarantees target specificity and stability, making our antibodies the reliable choice for your research needs.`,
        },


    ]



    return (
        <Layout>
            <Wrapper>
                <div className="container title-container bio-container">
                    <AboutHeader>
                        <h1>Identifyn™ De Novo Primary Antibodies</h1>
                    </AboutHeader>
                </div>
                <Content>
                <WrapperSmallGap alignItems='center'>
                <img src={toAbsoluteUrl('/media/primaryAntibody/image1.png')} alt="" style={{ width: '180px'}} />
                <HeadingContainer>
                <span>Identifyn’s™ De Novo Primary Antibody Development</span>
                </HeadingContainer>
                <HeadingContainer>
                <span>Identifyn™ SRM Alexa Fluor™ Conjugated Primary Antibodies</span>
                </HeadingContainer>
                </WrapperSmallGap>
                <CardContainer gradientBg='true' width='60vw' textAlignH4='left' textAlignSmall='center' mb_h4='16px'>
                    <WrapperSmallGap flexDirection='row'>
                    <div className='flex-wrapper'>
                        <h4  className='gradient-text'>Identifyn™ is committed to robust de novo development of <span>rigorously vetted, target-specific antibody affinity reagents</span> with a focus on <span>complete oncological pathways:</span></h4>
                        <ul>
                            <li>Cell Death</li>
                            <li>DNA Damage & Repair</li>
                            <li>Mitochondria</li>
                            <li>Cell Cycle</li>
                            <li>Cancer Growth & Spread</li>
                        </ul>
                    </div>
                        <div className='flex-wrapper'>
                            <img src={toAbsoluteUrl("/media/primaryAntibody/image2.png")} alt="" style={{ width:'100%', borderRadius:'12px'}} />
                            <small><em>Representative map of DNA damage and repair implicated in oncological pathway</em></small>
                        </div>
                    </WrapperSmallGap>
                </CardContainer>
                <WrapperSmallGap width='600px'>
                    <CardContainer background="rgba(237, 237, 237, 60%)" gap='24px' alignItems="center">
                        <h4>Primary Antibody Types</h4>
                        <WrapperSmallGap gap='8px'>
                        <CardContainer background='#fff' padding="16px" radius="12px" alignItems='center' textAlignH5='center'>
                            <h5>Mouse Monoclonal</h5>
                        </CardContainer>
                        <CardContainer background='#fff' padding="16px" radius="12px" alignItems='center' textAlignH5='center'>
                            <h5>Rabbit Polyclonal</h5>
                        </CardContainer>
                        <CardContainer background='#fff' padding="16px" radius="12px" alignItems='center' textAlignH5='center'>
                            <h5>Rabbit Recombinant Monoclonal</h5>
                        </CardContainer>
                        <CardContainer background='#fff' padding="16px" radius="12px" alignItems='center' textAlignH5='center'>
                            <h5>Fc Specific Rabbit Recombinant Monoclonal</h5>
                        </CardContainer>
                        </WrapperSmallGap>
                    </CardContainer>
                </WrapperSmallGap>
                <HeadingContainer>
                <span>Identifyn™ De Novo Antibody Produciton</span>
                </HeadingContainer>
                <div className="container solutions-container primary-container">
                    <div className="left-container" style={{ display: 'grid', gap: '16px' }}>
                        {
                            Solutions.map((item, index) => (
                                <div className="solutions-text" key={index}>
                                    <h4 style={{ cursor: 'pointer' }} onClick={() => setSolutionSlider(index)}>{item.title} <span style={{ marginLeft: '5px' }}><i className='fal fa-angle-down' /></span></h4>
                                    {solutionSlider === index && <p dangerouslySetInnerHTML={{ __html: item.description }}></p>}
                                </div>
                            ))
                        }
                    </div>
                    <div className="right-container">
                        <div className="wheel-container">
                            <img src={toAbsoluteUrl("/media/wheel/logo.png")} alt="" className='wheel-logo' />
                            <img src={toAbsoluteUrl("/media/primaryWheel/validation.png")} alt="" className='red' onClick={() => setSolutionSlider(3)} />
                            <img src={toAbsoluteUrl("/media/primaryWheel/activevalidation.png")} alt="" className={`red active-red ${solutionSlider === 3 ? 'active' : ''}`} onClick={() => setSolutionSlider(3)} />
                            <img src={toAbsoluteUrl("/media/primaryWheel/immunization.png")} alt="" className='blue' onClick={() => setSolutionSlider(0)} />
                            <img src={toAbsoluteUrl("/media/primaryWheel/activeimmunization.png")} alt="" className={`blue active-blue ${solutionSlider === 0 ? 'active' : ''}`} onClick={() => setSolutionSlider(0)} />
                            <img src={toAbsoluteUrl("/media/primaryWheel/isolation.png")} alt="" className='green' onClick={() => setSolutionSlider(1)} />
                            <img src={toAbsoluteUrl("/media/primaryWheel/activeisolation.png")} alt="" className={`green active-green  ${solutionSlider === 1 ? 'active' : ''}`} onClick={() => setSolutionSlider(1)} />
                            <img src={toAbsoluteUrl("/media/primaryWheel/hybridoma.png")} alt="" className='orange' onClick={() => setSolutionSlider(2)} />
                            <img src={toAbsoluteUrl("/media/primaryWheel/activehybridoma.png")} alt="" className={`orange active-orange ${solutionSlider === 2 ? 'active' : ''}`} onClick={() => setSolutionSlider(2)} />
                        </div>
                    </div>
                </div>
                <HeadingContainer width="90vw">
                <span>Discover the Unparalleled Validation Process for Identifyn’s™ Primary Antibodies, Designed to Ensure Reliability and Precision in Your Research!</span>
                </HeadingContainer>
                <WrapperSmallGap width='98vw'>
                    <CardContainer background="rgba(255, 242, 229, 48%)" gap='24px' alignItems="center" flexDirection='row' wrapperGap='50px'>
                        <h3 className='gradient-text'><span>Application Validation</span></h3>
                        <h4>Identifyn™ thoroughly validates the primary antibodies across a spectrum of microscopy techniques:</h4>
                        <div className='flex-wrapper'>
                        <ul style={{ gap: '16px'}}>
                            <li>Conventional Diffraction Limited Microscopy</li>
                            <li>Super Resolution Microscopy</li>
                        </ul>
                        <ul style={{ gap: '16px'}}>
                            <li>Single Molecule Microscopy</li>
                            <li>Immunofluorescent Histochemistry</li>
                        </ul>
                        </div>
                    </CardContainer>
                </WrapperSmallGap>
                <WrapperSmallGap width='600px'>
                    <CardContainer background="rgba(237, 237, 237, 60%)">
                        <h3>Rabbit anti-Human γH2Ax Recombinant Monoclonal, IgG</h3>
                        <img src={toAbsoluteUrl("/media/primaryAntibody/image3.png")} alt="Rabbit anti-Human γH2Ax Recombinant Monoclonal" style={{ width:'100%', borderRadius:'12px', margin:'16px 0'}} />
                        <h3>Single Molecule STORM Microscopy Bruker Vutara VXL</h3>
                    </CardContainer>
                </WrapperSmallGap>
                <HeadingContainer>
                <span>Identifyn™ SRM Alexa Fluor™ Conjugated Primary Antibodies</span>
                </HeadingContainer>
                <div className="graphs-container">
                        <div className="graphs-wrapper">
                            <Graph data={dyeBtns} />
                            <div className="button-container">
                                <button className={`btn blue ${dyeBtns === 405 && 'active-blue active'}`} onClick={() => setDyebtns(405)}>405</button>
                                <button className={`btn green ${dyeBtns === 488 && 'active-green active'}`} onClick={() => setDyebtns(488)}>488</button>
                                <button className={`btn yellow ${dyeBtns === 532 && 'active-yellow active'}`} onClick={() => setDyebtns(532)}>532</button>
                                <button className={`btn orange ${dyeBtns === 555 && 'active-orange active'}`} onClick={() => setDyebtns(555)}>555</button>
                                <button className={`btn coral ${dyeBtns === 594 && 'active-coral active'}`} onClick={() => setDyebtns(594)}>594</button>
                                <button className={`btn pink ${dyeBtns === 647 && 'active-pink active'}`} onClick={() => setDyebtns(647)}>647</button>
                                <button className={`btn red ${dyeBtns === 680 && 'active-red active'}`} onClick={() => setDyebtns(680)}>680</button>
                                <button className={`btn dark-red ${dyeBtns === 750 && 'active-dark-red active'}`} onClick={() => setDyebtns(750)}>750</button>
                            </div>
                        </div>
                </div>
                <WrapperSmallGap width='60vw'>
                    <CardContainer background="rgba(255, 242, 229, 48%)" gap='48px' alignItems="center">
                        <h4>Identifyn™ SRM Alexa Fluor™ Direct Conjugates Provide Higher Dye Ratios through Proprietary Conjugation</h4>
                        <ul>
                            <li>Multiplexing or Multiple Target Imaging</li>
                            <li>Lessen Immunostaining Time </li>
                            <li>Increasing the Resolution by ~15nm</li>
                        </ul>
                    </CardContainer>
                </WrapperSmallGap>
                <WrapperSmallGap width='98vw' flexDirection='row'>
                    <CardContainer color="#33c25e" background="rgba(237, 237, 237, 60%)">
                        <h3><span>Rabbit anti-Human γH2Ax Recombinant Monoclonal - Identifyn™ SRM Alexa Fluor™ 488, IgG</span></h3>
                        <img src={toAbsoluteUrl("/media/primaryAntibody/image4.png")} alt="Rabbit anti-Human γH2Ax Recombinant Monoclonal" style={{ width:'100%', borderRadius:'12px', margin:'16px 0'}} />
                        <h3>Super Resolution AiryScan Microscopy ZEISS LSM900</h3>
                    </CardContainer>
                    <CardContainer color="#33c25e" background="rgba(237, 237, 237, 60%)">
                    <h3><span>Rabbit anti-Human γH2Ax Recombinant Monoclonal - Identifyn™ SRM Alexa Fluor™ 555, IgG</span></h3>
                    <img src={toAbsoluteUrl("/media/primaryAntibody/image5.png")} alt="Rabbit anti-Human γH2Ax Recombinant Monoclonal" style={{ width:'100%', borderRadius:'12px', margin:'16px 0'}} />
                    <h3>Super Resolution SIM Microscopy ZEISS Elyra 5</h3>
                    </CardContainer>
                </WrapperSmallGap>
                <HeadingContainer width="98vw">
                <span>Identifyn™ offers to our Clients Custom-Made Primary Antibody Development Programs, a Wide Range of Fluorophore Conjugations, and Unparalleled Validation!</span>
                </HeadingContainer>
                </Content>
            </Wrapper>
            <Footer />
        </Layout>
    )
}

export default Index