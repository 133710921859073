export const printPdf = (url) => {
    fetch(url)
      .then((response) => response.arrayBuffer())
      .then((pdfBuffer) => {
        const pdfFile = new Blob([pdfBuffer], { type: "application/pdf" });
        const pdfUrl = URL.createObjectURL(pdfFile);
  
        const iframe = document.createElement("iframe");
        iframe.style.visibility = "hidden";
        iframe.style.position = "fixed";
        iframe.style.height = "100%";
        iframe.style.width = "100%";
        iframe.style.zIndex = "-999";
        iframe.src = pdfUrl;
        document.body.appendChild(iframe);
        iframe.focus();
        iframe.contentWindow.print();
      });
  };