import React, { useContext } from 'react'
import Layout from '../../../components/layout'
import { AboutHeader, AboutContent, Wrapper, Content, HeadingContainer, CardContainer, WrapperSmallGap, ImageContainer } from './style'
import { toAbsoluteUrl } from '../../../utils/assetHelper'
import { Footer } from '../../../components/Footer'
import { AuthContext } from '../../../context/auth'
import { downloadPdf } from '../../../utils/downloader'
import microscopyMethods from "./mircroscopyMethods.pdf"

const Index = () => {
  const { width } = useContext(AuthContext)
  return (
    <Layout>
      <Wrapper>
        <div className="container title-container bio-container">
          <span style={{ width: '44px', height: '44px', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: '12px', background: '#fff2e5', color: 'var(--primary-color)' }}>
            <i class="fal fa-microscope"></i>
          </span>
          <AboutHeader>
            <h1>Bennett Standards for Optical Microscopy Methods™</h1>
          </AboutHeader>
        </div>
        <AboutContent>
          <div style={{ marginBottom: '48px' }}>
            <HeadingContainer>
              <span>
                The Bennett Standards for Optical Microscopy Methods™ are the Result of Two Decades of Rigorous Research Dedicated to Optimizing Sample Preparation in Cell Culture and Tissue for Super Resolution Microscopy. This Method is Proven to be Highly Effective and Reproducible across all Forms of Microscopy and Applications, Enabling One Sample Preparation to Seamlessly Transition from Widefield Imaging to Single Molecule Analysis.
              </span>
            </HeadingContainer>
            <small style={{ float: 'right' }}><em>Patent Pending 2025</em></small>
          </div>
          <CardContainer background={'rgba(255,242,229, 0.48)'} alignItems={'start'} padding={'44px'} textAlignH2={'left'} style={{ marginBottom:'32px' }}>
            <h2>The Bennett Standards for Optical Microscopy Methods™ are the foundation of Identifyn™ Services and Assay Development, available to our clients in the following cost structures:</h2>
            <div style={{ margin: '48px 0'}}>
            <h2>- By the hour</h2>
            <h2>- By the sample</h2>
            <h2>- By dataset</h2>
            <h2>- Complete experimental design</h2>
            </div>
            <h2>Identifyn™ is committed to making super resolution microscopy accessible and affordable for the research community.</h2>
          </CardContainer>
          <div className="container azure-section-four-container" style={{ background: 'rgba(237, 237, 237, 0.20)', alignItems: 'start', gap: '40px' }}>
            <div className="methods-header">
              <h5>Section</h5>
              <div className='inner-header'>
                <h5>Bennett Standards for Optical Microscopy Methods™</h5>
                {width > 700 && <button className='btn' style={{ background: '#FFF2E5', color: '#DC4405' }}
                  onClick={() => downloadPdf(microscopyMethods, 'microscopyMethods.pdf')}
                ><i className='fal fa-arrow-to-bottom' /> Download PDF</button>}
              </div>
            </div>
            <div className="methods-header">
              <p>Cells</p>
              <p><span>1.</span>Cell physiology is affected by the increased variability in seeding concentration and plating time. At Identifyn™, low passage number cells (4-6) are used at 60% confluency before fixation.</p>
            </div>
            <div className="methods-header">
              <p>Cellular Effect</p>
              <p><span>2.</span>Cellular manipulation can be effectively achieved by strategically inducing cellular responses to various factors within the cellular environment. This can be accomplished through techniques such as transfection, the use of pharmaceuticals, or by leveraging other metabolic influences.</p>
            </div>
            <div className="methods-header">
              <p>Coverslip Preparation</p>
              <p><span>3.</span>The preparation of coverslips (1.5H), or other imaging containers can be a vital process that enhances cellular adherence. Techniques such as acid etching or coating with substrates like Poly-D-Lysine are employed to improve adhesion. Additionally, various methods may be utilized when handling tissue samples or biopsies. This important step plays a key role in preserving the cellular environment, ultimately leading to high-quality imaging that remains undisturbed.</p>
            </div>
            <div className="methods-header">
              <p>Fixation</p>
              <div>
              <p><span>4.</span>
              When selecting a fixation reagent, it's important to choose one that is appropriate for your specific protein target and to compare the effectiveness of different fixation methods.
              </p>
              <ul>
                  <li>Methanol Fixation: This method is particularly effective for non-membrane-bound proteins and produces minimal autofluorescence. As an example, cells may be in methanol at -20 °C for 8 to 10 minutes, which is ideal for targeting nuclear proteins.</li>
                  <li>PFA (Paraformaldehyde) Fixation: As an example, a 4% (v/v) solution of PFA is effective when incubated for 10 minutes at room temperature; however, using PFA at 37 °C yields even better results.</li>
                  <li>Glutaraldehyde-based Fixation: This method is highly effective for preserving the target structure but typically results in high autofluorescence. Therefore, it is important to include appropriate quenching steps.</li>
                </ul>
              </div>
            </div>
            <div className="methods-header">
              <p>Blocking</p>
              <div>
              <p><span>5.</span>Sample preparation includes a blocking step to minimize non-specific binding of primary and secondary antibodies. Conventional blocking agents, such as BSA, goat serum, or fetal calf serum, can be used in varying concentrations in PBS.
                <br /> <br />
                However, these agents can exhibit different levels of cross-reactivity with mammalian reagents. Identifyn™ has found that using a marine blocking agent effectively eliminates background fluorescence and non-specific binding, as it contains essentially no antigens that could potentially cross-react.
              </p>
              <WrapperSmallGap justifyContent='center' alignItems='center' marginBottom={'44px'} style={{ marginTop: '32px'}}>
                <img src={toAbsoluteUrl('/media/microscopy/image1.png')} />
              <p>HeLa cells fixed with methanol (-20 °C, 8 min), blocked with (A) Marine Block (B) 5% non-fat dry milk or (C) 5% BSA and stained with secondary antibody at 1:250 dilution. Images were collected with same acquisition settings. Marine Block was developed by Dr. Brian Bennett.</p>
              <a href='https://pmc.ncbi.nlm.nih.gov/articles/PMC3687552/' target='_blank' style={{color:'#009cea'}}>Reference: Bennett BT, Bewersdorf J, Knight KL. Immunofluorescence imaging of DNA damage response proteins: optimizing protocols for super-resolution microscopy. Methods. 2009;48(1):63-71. doi:10.1016/j.ymeth.2009.02.009</a>
              </WrapperSmallGap>
              <h4>Steps 6 and 7 can be replaced by step 8 if you are using a Direct Primary Conjugate</h4>
              </div>
            </div>
            <div className="methods-header">
              <p>Primary Antibody Staining </p>
              <p><span>6.</span>When staining for multiple primary targets, it is crucial to avoid creating pre-mixed cocktails of primary or secondary antibodies. Instead, it is recommended to perform the staining sequentially, with thorough washing and blocking between each step. Using cocktails can lead to non-specific colocalization. To ensure accurate localization and structure of individual targets during double-staining, validate the results with corresponding single-stain observations.
                <br /> <br />
                Typically, the primary antibody should be used at a concentration between 1:100 and 1:1000, depending on the application's specific requirements, and it should be diluted in a blocking buffer with PBS.
                <br /> <br />
                Incubation usually lasts for 60 minutes with continuous rocking, or it can be done overnight at 4°C.
                <br /> <br />
                After incubating with the primary antibody, wash thoroughly with PBS.</p>
            </div>
            <div className="methods-header">
              <p>Secondary Antibody Staining</p>
              <p><span>7.</span>After incubating with the primary antibody, wash the samples thoroughly with PBS. The secondary antibody is usually used at a concentration between 1:1000 and 1:5000, depending on the specific requirements of the end user’s application.
                <br /> <br />
                This should be prepared in a buffer containing blocking media in PBS.
                <br /> <br />
                Incubate the samples for 60 minutes with continuous rocking.
                <br /> <br />
                After incubating with the secondary antibody, wash the samples thoroughly with PBS again.
                <br /> <br /> <br />
                or
              </p>
            </div>
            <div className="methods-header">
              <p>Direct Conjugate Staining </p>
              <p><span>8.</span>Identifyn™ SRM Alexa Fluor™ Primary Antibody conjugates can be used instead of Primary Antibodies after step 4 in this method.
              <br/> <br/>
              A secondary antibody will not be required if these primary antibody dye conjugates are used. For multiple targets, sequential staining with intermittent washing and blocking is preferable. Direct conjugates enable multi target visualization and are especially useful in various high-throughout applications.
              </p>
            </div>
            <div className="methods-header">
              <p>Coverslip Mounting</p>
              <p><span>9.</span>Coverslips are mounted using appropriate mounting media per application. In our applications, we use ProLong™ Diamond Antifade Mountant with DAPI.
              <br/> <br/>
              Commercial mounting media aids in adjusting the refractive index and reducing photobleaching. The refractive index adjustments can also be achieved using 80-90% glycerol in PBS combined with oxygen scavenging systems.​
              </p>
            </div>
            {width < 700 && <button className='btn' style={{ background: '#FFF2E5', color: '#DC4405', width: '100%' }}
              onClick={() => downloadPdf(microscopyMethods, 'microscopyMethods.pdf')}
            ><i className='fal fa-arrow-to-bottom' /> Download PDF</button>}
          </div>
          <HeadingContainer style={{ marginBottom: '32px' }}>
            <span>
              Bennett Standards for Optical Microscopy Methods™ empower users to consistently apply Identifyn’s™ patent-pending resolution step-down methodology with precision, reliability and absolute reproducibility.
            </span>
          </HeadingContainer>
          <CardContainer background={'rgba(255,242,229, 0.48)'} alignItems={'start'} padding={'44px'} textAlignH2={'left'} style={{ marginBottom:'32px' }}>
            <h2>The Bennett Standards for Optical Microscopy Methods™ in conjunction with Identifyn’s™ Patent Pending Resolution Step Down Method Revolutionize Data Collection, making it both Affordable and Accessible to the Research Community.</h2>
            <div style={{ marginTop: '48px'}}>
            <h2>- By the hour</h2>
            <h2>- By the sample</h2>
            <h2>- By dataset</h2>
            <h2>- Complete experimental design</h2>
            </div>
          </CardContainer>
          <CardContainer background={'rgba(255,242,229, 0.48)'} gap={'24px'} alignItems={'center'} padding={'44px'} style={{ marginBottom:'32px' }}>
            <h4><span>The Bennett Methodology™ revolutionizes Resolution Walkdowns, making them an indispensable tool for effective study.</span></h4>
            <h4>To truly grasp the complexities of a single target protein, it's not just beneficial but essential to analyze it at various resolutions in both 2D and 3D. This multifaceted approach unlocks a deeper understanding that can drive significant advancements in research.</h4>
            <h4>For instance, high-throughput widefield imaging uncovers crucial insights into cellular phenotype and physiology, laying a strong foundation for further exploration. Confocal and AiryScan imaging provide critical structural data that enrich our knowledge, while SIM and SIM² techniques enable the visualization of intricate sub-diffraction structures. Moreover, single-molecule microscopy allows for the precise and sensitive quantification of the smallest functional units within these structures, elevating the quality of our findings.</h4>
            <h4>Relying solely on 2D or low-resolution 3D colocalization studies can lead to incorrect conclusions, putting the integrity of your research at risk. By validating these findings through high axial resolution imaging, you can ensure the accuracy and reliability of your work. Embrace the power of comprehensive analysis to elevate your study and contribute impactful knowledge to the field</h4>
          </CardContainer>
          <CardContainer background={'transparent'} gap={'24px'} alignItems={'center'} padding={'0'} style={{ marginBottom:'32px'}}>
            <h4><span>DNA Damage Response Visualized by gH2AX across Resolutions</span></h4>
            <WrapperSmallGap flexDirection={'row'} justifyContent={'center'} alignItems={'center'} style={{flexWrap:'wrap'}} imgWidth={'auto'}>
            <img src={toAbsoluteUrl('/media/microscopy/image2.png')} />
            <img src={toAbsoluteUrl('/media/microscopy/image3.png')} />
            <img src={toAbsoluteUrl('/media/microscopy/image4.png')} />
            <img src={toAbsoluteUrl('/media/microscopy/image5.png')} />
            </WrapperSmallGap>
          </CardContainer>
          <CardContainer background={'transparent'} gap={'24px'} alignItems={'center'} padding={'0'} style={{ marginBottom:'32px'}}>
            <h4><span>STORM Image of RAD51 Foci in XY shows Doublet, while XZ rendering Reveals Axial Separation</span></h4>
            <WrapperSmallGap flexDirection={'row'} justifyContent={'center'} alignItems={'center'} style={{flexWrap:'wrap'}} imgWidth={'auto'}>
            <img src={toAbsoluteUrl('/media/microscopy/image6.png')} />
            <img src={toAbsoluteUrl('/media/microscopy/image7.png')} />
            </WrapperSmallGap>
          </CardContainer>
          <CardContainer background={'rgba(255,242,229, 0.48)'} gap={'24px'} alignItems={'center'} padding={'44px'} style={{ marginBottom:'32px' }}>
            <h4><span>Identifyn™ is dedicated to utilizing top-quality products and instruments in our services and assays.</span></h4>
            <h4>We believe that exceptional imaging begins with high-quality samples. In this document, we are pleased to share our methodology, and we encourage you to consider incorporating our approach into your own research.</h4>
            <h4>Recognizing the diverse nature of microscopy equipment and applications, this method is intended as a general guideline to support your efforts. We hope it inspires you and enhances your work in microscopy.</h4>
          </CardContainer>
          <div className="container services-section-two-container">
            <div className="left-container">
              <div className="card2">
                <h3>Reagents</h3>
                <p style={{ marginBottom: '16px', color: '#6c6a73', fontSize: '16px' }}>Click on a reagent to visit our partner website</p>
                <div className="points-container">
                  <div className="points">
                    <img src={toAbsoluteUrl("/media/pointbullet2.svg")} alt="" />
                    <p>Cells – Application Specific</p>
                  </div>
                  <div className="points">
                    <img src={toAbsoluteUrl("/media/pointbullet2.svg")} alt="" />
                    <p>100% Methanol</p>
                  </div>
                  <div className="points">
                    <img src={toAbsoluteUrl("/media/pointbullet2.svg")} alt="" />
                    <p>PBS pH 7.4</p>
                  </div>
                  <div className="points">
                    <img src={toAbsoluteUrl("/media/pointbullet2.svg")} alt="" />
                    <a href="https://www.thermofisher.com/order/catalog/product/37527" target='_blank'><p>Fish Serum Buffer <i className='fal fa-eye' style={{ fontSize: '16px', color: '#DC4405' }} /></p></a>
                  </div>
                  <div className="points">
                    <img src={toAbsoluteUrl("/media/pointbullet2.svg")} alt="" />
                    <a href="https://www.thermofisher.com/order/catalog/product/P36962" target='_blank'><p>ProLong™ Diamond Antifade Mountant with
                      DAPI <i className='fal fa-eye' style={{ fontSize: '16px', color: '#DC4405' }} /></p></a>
                  </div>
                  <div className="points">
                    <img src={toAbsoluteUrl("/media/pointbullet2.svg")} alt="" />
                    <p>Primary Antibody</p>
                  </div>
                  <div className="points">
                    <img src={toAbsoluteUrl("/media/pointbullet2.svg")} alt="" />
                    <p>Secondary Antibody</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="right-container">
              <div className="card2">
                <h3>Consumables</h3>
                <p style={{ marginBottom: '16px', color: '#6c6a73', fontSize: '16px' }}>Click on a consumable to visit our partner website</p>
                <div className="points-container">
                  <div className="points">
                    <img src={toAbsoluteUrl("/media/pointbullet2.svg")} alt="" />
                    <a href="https://www.fishersci.com/shop/products/costar-cell-culture-plates-1/07-200-83?searchHijack=true&searchTerm=costar-cell-culture-plates-1&searchType=Rapid&matchedCatNo=07-200-83" target='_blank'><p>6-well Tissue Culture Treated Plates <i className='fal fa-eye' style={{ fontSize: '16px', color: '#DC4405' }} /></p></a>
                  </div>
                  <div className="points">
                    <img src={toAbsoluteUrl("/media/pointbullet2.svg")} alt="" />
                    <a href="https://www.fishersci.com/shop/products/microslide-8-well-glass-bottom/NC0704855" target='_blank'><p>iBidi 8 Well Chambered µ-Slides <i className='fal fa-eye' style={{ fontSize: '16px', color: '#DC4405' }} /></p></a>
                  </div>
                  <div className="points">
                    <img src={toAbsoluteUrl("/media/pointbullet2.svg")} alt="" />
                    <a href="https://www.fishersci.com/shop/products/precision-coverglass-1000-pk/NC1415511" target='_blank'><p>#1.5H Coverslips <i className='fal fa-eye' style={{ fontSize: '16px', color: '#DC4405' }} /></p></a>
                  </div>
                  <div className="points">
                    <img src={toAbsoluteUrl("/media/pointbullet2.svg")} alt="" />
                    <a href="https://www.fishersci.com/shop/products/fisherbrand-superfrost-disposable-microscope-slides-5/12-550-123?searchHijack=true&searchTerm=fisherbrand-superfrost-disposable-microscope-slides-5&searchType=Rapid&matchedCatNo=12-550-123" target='_blank'><p>Glass Slides <i className='fal fa-eye' style={{ fontSize: '16px', color: '#DC4405' }} /></p></a>
                  </div>
                  <div className="points">
                    <img src={toAbsoluteUrl("/media/pointbullet2.svg")} alt="" />
                    <a href="https://www.thermofisher.com/order/catalog/product/170355N" target='_blank'><p>5mL Serological Pipette <i className='fal fa-eye' style={{ fontSize: '16px', color: '#DC4405' }} /></p></a>
                  </div>
                  <div className="points">
                    <img src={toAbsoluteUrl("/media/pointbullet2.svg")} alt="" />
                    <a href="https://www.thermofisher.com/order/catalog/product/170356N" target='_blank'><p>10mL Serological Pipette <i className='fal fa-eye' style={{ fontSize: '16px', color: '#DC4405' }} /></p></a>
                  </div>
                  <div className="points">
                    <img src={toAbsoluteUrl("/media/pointbullet2.svg")} alt="" />
                    <a href="https://www.fishersci.com/shop/products/fisherbrand-disposable-borosilicate-glass-pasteur-pipets-4/13-678-20B" target='_blank'><p>Glass Aspirator Tips <i className='fal fa-eye' style={{ fontSize: '16px', color: '#DC4405' }} /></p></a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="container services-section-two-container" style={{ gridTemplateColumns: '1fr' }}>
            <div className="card1">
              <h3>Identifyn’s™ Microscopes</h3>
              <div className="points-container">
                <div className="points">
                  <img src={toAbsoluteUrl("/media/pointbullet.svg")} alt="" />
                  <p>Zeiss Axioscope Widefield Microscope with sCMOS</p>
                </div>
                <div className="points">
                  <img src={toAbsoluteUrl("/media/pointbullet.svg")} alt="" />
                  <p>Zeiss Apotome Structured Illumination (SIM) Microscope with sCMOS Full Automation</p>
                </div>
                <div className="points">
                  <img src={toAbsoluteUrl("/media/pointbullet.svg")} alt="" />
                  <p>Zeiss Apotome Structured Illumination (SIM) Microscope
                  Specifically Dedicated to High-Throughput Antibody Screening</p>
                </div>
                <div className="points">
                  <img src={toAbsoluteUrl("/media/pointbullet.svg")} alt="" />
                  <p>Zeiss LSM, 900 Confocal with Super Resolution AiryScan Microscope</p>
                </div>
                <div className="points">
                  <img src={toAbsoluteUrl("/media/pointbullet.svg")} alt="" />
                  <p>Zeiss LSM, 980 Confocal with Super Resolution AiryScan Microscope and NIR Capabilities</p>
                </div>
                <div className="points">
                  <img src={toAbsoluteUrl("/media/pointbullet.svg")} alt="" />
                  <p>Zeiss Elyra 5 with Super Resolution Lattice-Structured Illumination (SIM) and SIM² Microscope
                  </p>
                </div>
                <div className="points">
                  <img src={toAbsoluteUrl("/media/pointbullet.svg")} alt="" />
                  <p>Bruker Vutara VXL, Super Resolution Bi-plane
                  </p>
                </div>
              </div>
            </div>
          </div>
        </AboutContent>
      </Wrapper>
      <Footer />
    </Layout>
  )
}

export default Index