import React, { useContext, useEffect, useState, useRef } from 'react'
import Layout from '../../../components/layout'
import { toAbsoluteUrl } from '../../../utils/assetHelper'
import { useNavigate } from 'react-router-dom'
import { Wrapper } from './style'
import Graph from './graph'
import MethodologyModal from "../../../components/modals/MethodologyModal"
import { Tooltip as ReactTooltip } from "react-tooltip";
import { getProductApi, createCartApi, addItemsToCartApi, getCoaApi } from '../../../api'
import { AuthContext } from '../../../context/auth'
import ReactPrint from "react-to-print";
import Coa from '../../../components/coA'
import { getLocalStorage, setLocalStorage } from "../../../helpers/localStorage";
import { isVideoFile, getMp4Url } from '../../../utils/videoHelper'
import { isValidURL } from '../../../utils/urlHelper'
import { printPdf } from '../../../utils/print'
import Pagination from '../../../components/pagination'
import Dropdown from "../../../components/Dropdown"


const Index = () => {
    const coARef = useRef(null)
    const navigate = useNavigate()
    const cartId = getLocalStorage('cartId')
    const { setIsLoading, setNotification, isLoading, setCartData, tempDisable, setTempDisable, width } = useContext(AuthContext)
    const [productSelection, setProductSelection] = useState({
        host: '',
        species_reactivity: {
            label: '',
            value: ''
        },
        full_length: {
            label: '',
            value: ''
        }
    })
    const [dyeBtns, setDyebtns] = useState();
    const [openMethodologyModal, setOpenMethodologyModal] = useState(false)
    const [products, setProducts] = useState([])
    const [selectedProduct, setSelectedProduct] = useState(null)
    const [modalName, setModalName] = useState(null)
    const [images, setImages] = useState([])
    const [imageSlideIndex, setImageSlideIndex] = useState(0)
    const [currentPage, setCurrentPage] = useState(0);

    useEffect(() => {
        if (selectedProduct?.metadata?.images) {
            const arr = selectedProduct?.metadata?.images?.filter((item) => item.url !== '')
            const splitArr = Array.from({ length: Math.ceil(arr.length / 4) }, (_, index) =>
                arr.slice(index * 4, index * 4 + 4)
            );
            setImages(splitArr)
        }

    }, [selectedProduct])


    useEffect(() => {
        if (dyeBtns) {
            setIsLoading(true)
            console.log(productSelection?.host)
            getProductApi('SA', dyeBtns, 5, currentPage * 5, productSelection?.host, productSelection?.species_reactivity?.value, productSelection?.full_length?.value).then((res) => {
                if (res && res.data && res.data?.products) {
                    setProducts(res?.data)
                    setIsLoading(false)
                } else {
                    setSelectedProduct(null)
                    setIsLoading(false)
                }
            })
        }

    }, [productSelection, dyeBtns, currentPage])


    const AddToCart = () => {
        setIsLoading(true)
        if (!cartId) {
            createCartApi().then(res => {
                setLocalStorage('cartId', res.data.cart.id)
                addItemsToCartApi(res.data.cart.id, {
                    variant_id: selectedProduct?.variants[0]?.id,
                    quantity: 1,
                }).then((res) => {
                    if (res && res.data && res.data?.cart) {
                        setCartData(res.data.cart)
                        setNotification({
                            message: 'Added to cart',
                            type: 'green',
                        })
                        setIsLoading(false)
                    } else {
                        setNotification({
                            message: 'Something went wrong',
                            type: 'red',
                        })
                        setIsLoading(false)
                    }
                })
            })
        } else {
            addItemsToCartApi(cartId, {
                variant_id: selectedProduct?.variants[0]?.id,
                quantity: 1,
            }).then((res) => {
                if (res && res.data && res.data?.cart) {
                    setCartData(res.data.cart)
                    setNotification({
                        message: 'Added to cart',
                        type: 'green',
                    })
                    setIsLoading(false)
                } else {
                    setNotification({
                        message: 'Something went wrong',
                        type: 'red',
                    })
                    setIsLoading(false)
                }
            })
        }
    }

    const BuyProduct = () => {
        setIsLoading(true)
        if (!cartId) {
            createCartApi().then(res => {
                setLocalStorage('cartId', res.data.cart.id)
                addItemsToCartApi(res.data.cart.id, {
                    variant_id: selectedProduct?.variants[0]?.id,
                    quantity: 1,
                }).then((res) => {
                    if (res && res.data && res.data?.cart) {
                        setCartData(res.data.cart)
                        setNotification({
                            message: 'Added to cart',
                            type: 'green',
                        })
                        setIsLoading(false)
                        navigate('/checkout')
                    } else {
                        setNotification({
                            message: 'Something went wrong',
                            type: 'red',
                        })
                        setIsLoading(false)
                    }
                })
            })
        } else {
            addItemsToCartApi(cartId, {
                variant_id: selectedProduct?.variants[0]?.id,
                quantity: 1,
            }).then((res) => {
                if (res && res.data && res.data?.cart) {
                    setCartData(res.data.cart)
                    setNotification({
                        message: 'Added to cart',
                        type: 'green',
                    })
                    setIsLoading(false)
                    navigate('/checkout')
                } else {
                    setNotification({
                        message: 'Something went wrong',
                        type: 'red',
                    })
                    setIsLoading(false)
                }
            })
        }
    }
  
        const printCoa = (id) => {
            setIsLoading(true)
            getCoaApi(id).then((response) => {
            const blob = new Blob([response.data], { type: 'application/pdf' })
            const url = window.URL.createObjectURL(blob)
            setIsLoading(false)
            printPdf(url)
            }).catch(() => {
                setIsLoading(false)
            })
        }


    return (
        <Layout>
            <Wrapper>
                <div className="selection-container">
                    <h3>Identifyn™ SRM Alexa Fluor™ Secondary Fluorescent Antibodies</h3>
                    {(productSelection?.host || productSelection?.species_reactivity?.value || productSelection?.full_length?.value || dyeBtns) && <button className='btn transparent' style={{ float: 'right' }}
                        onClick={() => {
                            setProductSelection({
                                host: '',
                                species_reactivity: {
                                    label: '',
                                    value: ''
                                },
                                full_length: {
                                    label: '',
                                    value: ''
                                }
                            })
                            setDyebtns()
                            setCurrentPage(0)
                        }}
                    >Clear All</button>}
                    <div className="selections-wrapper">
                        <div className="select-container">
                            <h4>Host</h4>
                            <Dropdown
                                optionType='array'
                                options={['Donkey', 'Goat', 'Rabbit']}
                                value={productSelection.host}
                                label={'Select Host'}
                                clear
                                onClear={() => {
                                    setProductSelection({
                                        ...productSelection,
                                        host: ''
                                    })
                                    setCurrentPage(0)
                                }}
                                onChange={(e) => {
                                    if (productSelection.host !== e) {
                                        setProductSelection({
                                            ...productSelection,
                                            host: e
                                        })
                                        setCurrentPage(0)
                                    } else {
                                        setProductSelection({
                                            ...productSelection,
                                            host: ''
                                        })
                                        setCurrentPage(0)
                                    }
                                }}
                                style={{ width: '100%' }}
                            />
                            {/* <div className="input-wrapper">
                                <button className={`btn ${productSelection?.host === 'Donkey' && 'active-btn'}`}
                                    onClick={() => setProductSelection({
                                        ...productSelection,
                                        host: 'Donkey'
                                    })}>Donkey</button>
                                <button className={`btn ${productSelection?.host === 'Goat' && 'active-btn'}`}
                                    onClick={() => setProductSelection({
                                        ...productSelection,
                                        host: 'Goat'
                                    })}
                                >Goat</button>
                                <button className={`btn ${productSelection?.host === 'Rabbit' && 'active-btn'}`}
                                    onClick={() => setProductSelection({
                                        ...productSelection,
                                        host: 'Rabbit'
                                    })}
                                >Rabbit</button>
                            </div> */}
                        </div>
                        <div className="select-container">
                            <h4>Species Reactivity</h4>
                            <Dropdown
                                optionType='object'
                                options={[
                                    {
                                        value: 'Anti-Human',
                                        label: 'Human'
                                    },
                                    {
                                        value: 'Anti-Mouse',
                                        label: 'Mouse'
                                    },
                                    {
                                        value: 'Anti-Rabbit',
                                        label: 'Rabbit'
                                    },
                                    {
                                        value: 'Anti-Rat',
                                        label: 'Rat'
                                    }
                                ]}
                                value={productSelection.species_reactivity.label}
                                label={'Select Species Reactivity'}
                                clear
                                onClear={() => {
                                    setProductSelection({
                                        ...productSelection,
                                        species_reactivity: {
                                            label: '',
                                            value: ''
                                        }
                                    })
                                    setCurrentPage(0)
                                }}
                                onChange={(e) => {
                                    if (productSelection.species_reactivity.label !== e.label) {
                                        setProductSelection({
                                            ...productSelection,
                                            species_reactivity: e
                                        })
                                        setCurrentPage(0)
                                    } else {
                                        setProductSelection({
                                            ...productSelection,
                                            species_reactivity: {
                                                label: '',
                                                value: ''
                                            }
                                        })
                                        setCurrentPage(0)
                                    }
                                }}
                                style={{ width: '100%' }}
                            />
                            {/* <div className="input-wrapper">
                                <button className={`btn ${productSelection?.species_reactivity === 'Anti-Human' && 'active-btn'}`}
                                    onClick={() => setProductSelection({
                                        ...productSelection,
                                        species_reactivity: 'Anti-Human'
                                    })}
                                >Human</button>
                                <button className={`btn ${productSelection?.species_reactivity === 'Anti-Mouse' && 'active-btn'}`}
                                    onClick={() => setProductSelection({
                                        ...productSelection,
                                        species_reactivity: 'Anti-Mouse'
                                    })}
                                >Mouse</button>
                                <button className={`btn ${productSelection?.species_reactivity === 'Anti-Rabbit' && 'active-btn'}`}
                                    onClick={() => setProductSelection({
                                        ...productSelection,
                                        species_reactivity: 'Anti-Rabbit'
                                    })}
                                >Rabbit</button> */}
                            {/* <button className={`btn ${productSelection?.species_reactivity === 'Rat' && 'active-btn'}`}
                                    onClick={() => setProductSelection({
                                        ...productSelection,
                                        species_reactivity: 'Rat'
                                    })}
                                >Rat</button> */}
                            {/* </div> */}
                        </div>
                        <div className="select-container">
                            <h4>Full Length or Fab</h4>
                            <Dropdown
                                optionType='object'
                                options={[
                                    {
                                        label: 'Whole IgG',
                                        value: 'IgG'
                                    },
                                    {
                                        label: 'F(ab’)₂',
                                        value: 'F(ab’)₂'
                                    }
                                ]}
                                value={productSelection.full_length?.label}
                                label={'Select Full Length or Fab'}
                                clear
                                onClear={() => {
                                    setProductSelection({
                                        ...productSelection,
                                        full_length: {
                                            label: '',
                                            value: ''
                                        }
                                    })
                                    setCurrentPage(0)
                                }}
                                onChange={(e) => {
                                    if (productSelection.full_length?.label !== e.label) {
                                        setProductSelection({
                                            ...productSelection,
                                            full_length: e
                                        })
                                        setCurrentPage(0)
                                    } else {
                                        setProductSelection({
                                            ...productSelection,
                                            full_length: {
                                                label: '',
                                                value: ''
                                            }
                                        })
                                        setCurrentPage(0)
                                    }
                                }}
                                style={{ width: '100%' }}
                            />
                            {/* <div className="input-wrapper">
                                <button className={`btn ${productSelection?.full_length === 'IgG' && 'active-btn'}`}
                                    onClick={() => setProductSelection({
                                        ...productSelection,
                                        full_length: 'IgG'
                                    })}
                                >Whole IgG</button>
                                <button className={`btn ${productSelection?.full_length === "F(ab’)₂" && 'active-btn'}`}
                                    onClick={() => setProductSelection({
                                        ...productSelection,
                                        full_length: "F(ab’)₂"
                                    })}
                                >F(ab’)₂</button>
                            </div> */}
                        </div>
                    </div>
                    <div className="select-container" style={{ marginTop: '32px' }}>
                        <h4>Select Dye</h4>
                        <div className="graphs-wrapper">
                            {/* <img src={toAbsoluteUrl("/media/flourescence/Default.svg")} alt="" /> */}
                            <Graph data={dyeBtns} />
                            <div className="button-container">
                                <button className={`btn blue ${dyeBtns === 405 && 'active-blue active'}`} onClick={() => {
                                    setDyebtns(405)
                                    setCurrentPage(0)
                                }}>405</button>
                                <button className={`btn green ${dyeBtns === 488 && 'active-green active'}`} onClick={() => {
                                    setDyebtns(488)
                                    setCurrentPage(0)
                                }}>488</button>
                                <button className={`btn yellow ${dyeBtns === 532 && 'active-yellow active'}`} onClick={() => {
                                    setDyebtns(532)
                                    setCurrentPage(0)
                                }}>532</button>
                                <button className={`btn orange ${dyeBtns === 555 && 'active-orange active'}`} onClick={() => {
                                    setDyebtns(555)
                                    setCurrentPage(0)
                                }}>555</button>
                                <button className={`btn coral ${dyeBtns === 594 && 'active-coral active'}`} onClick={() => {
                                    setDyebtns(594)
                                    setCurrentPage(0)
                                }}>594</button>
                                <button className={`btn pink ${dyeBtns === 647 && 'active-pink active'}`} onClick={() => {
                                    setDyebtns(647)
                                    setCurrentPage(0)
                                }}>647</button>
                                <button className={`btn red ${dyeBtns === 680 && 'active-red active'}`} onClick={() => {
                                    setDyebtns(680)
                                    setCurrentPage(0)
                                }}>680</button>
                                <button className={`btn dark-red ${dyeBtns === 750 && 'active-dark-red active'}`} onClick={() => {
                                    setDyebtns(750)
                                    setCurrentPage(0)
                                }}>750</button>
                            </div>
                        </div>
                    </div>
                    {products?.count > 0 ?
                        <>
                            <div className="list-container">
                                <h5 style={{ marginBottom: '20px', fontWeight: 500 }}>*Excitation and emission values using common filters</h5>
                                <h3>Protein Lists</h3>
                                {width > 750 ? <table className={"mainTable"}>
                                    <thead>
                                        <tr>
                                            <th>Protein Target</th>
                                            <th>Cat ID#</th>
                                            <th>Price </th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {products?.products && products?.products?.map((business) => {
                                            return (
                                                <tr
                                                    key={business?.id}
                                                >
                                                    <td style={{ width: '380px' }}>
                                                        {business?.title ? business?.title : "-"}
                                                    </td>
                                                    <td>
                                                        {business?.material ? business?.mid_code + " " + business?.material : "-"}
                                                    </td>
                                                    <td><span style={{ fontWeight: 600 }}>{`$${(business?.variants[0]?.prices[0]?.amount / 100)?.toFixed(2)}` || "-"}</span></td>
                                                    <td style={{ cursor: "pointer" }}>
                                                        <span style={{ display: 'flex', gap: '30px', alignItems: 'center', justifyContent: 'flex-end' }}>
                                                            <button className='btn buy-btn'
                                                                onClick={() => setSelectedProduct(business)}
                                                            >Select</button>
                                                        </span>
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </table>
                                    :
                                    <div className='products-cards-container'>
                                        {products?.products && products?.products?.map((business) => {
                                            return <div className="product-card">
                                                <h4>{business.title ? business?.title : '-'}</h4>
                                                <div className="product-double-column">
                                                    <div className="label-name">
                                                        <h5>Cat ID#</h5>
                                                        <h4>{business?.material ? business?.mid_code + " " + business?.material : "-"}</h4>
                                                    </div>
                                                    <div className="label-name" style={{ textAlign: 'right' }}>
                                                        <h5>Price</h5>
                                                        <h4><span style={{ color: 'var(--primary-color)', fontWeight: '600' }}>{`$${(business?.variants[0]?.prices[0]?.amount / 100)?.toFixed(2)}` || "-"}</span></h4>
                                                    </div>
                                                </div>
                                                <button className='btn'
                                                    style={{ marginTop: '12px' }}
                                                    onClick={() => setSelectedProduct(business)}
                                                >Select</button>
                                            </div>
                                        })}
                                    </div>
                                }
                                {products?.totalcount / products.limit > 1 && <Pagination total={products?.totalcount / products.limit} setCurrentPage={setCurrentPage} currentPage={currentPage} />}
                            </div>
                        </>
                        : dyeBtns && <div className='no-orders' style={{ height: '400px' }}>
                            <span className='icon'>
                                <i class="fal fa-boxes"></i>
                            </span>
                            <p>No Products Available Yet.</p>
                        </div>
                    }
                    <p style={{ fontSize: '12px', marginTop: '28px', marginBottom: '10px' }}>© Copyright 2023-2024 Identifyn™ LLC & GMD12™ LLC, All rights reserved</p>
                    <p style={{ fontSize: '12px', marginBottom: '16px' }}>Patent Pending Identifyn™ LLC & GMD12™ LLC</p>
                </div>
                <div className={`product-container ${selectedProduct ? 'show-product' : 'hide-product'}`}>
                    {selectedProduct ?
                        <>
                            <div className="product-wrapper">
                                <button className='btn transparent' onClick={() => {
                                    // setProductSelection({
                                    //     host: '',
                                    //     species_reactivity: '',
                                    //     full_length: '',
                                    // })
                                    // setDyebtns('')
                                    setSelectedProduct(null)
                                    setModalName(null)
                                    setImageSlideIndex(0)
                                    setImages([])
                                }
                                }>Clear</button>
                                <div className="item-details-container">
                                    <div className="item-detail-header">
                                        <div className="left-container">
                                            <h4 style={{ color: 'var(--primary-color)' }}>{selectedProduct?.title}</h4>
                                            <h5 style={{ marginTop: '5px' }}>Cat ID# - {selectedProduct?.mid_code} {selectedProduct?.material}</h5>
                                        </div>
                                        {/* <ReactPrint
                                            onBeforeGetContent={() => {
                                                setIsLoading(true)
                                            }}
                                            onAfterPrint={() => {
                                                setIsLoading(false)
                                            }}
                                            trigger={() => <span className='coa' style={{ cursor: 'pointer', alignSelf: 'flex-start' }}>
                                                CoA</span>}
                                            content={() => coARef.current}
                                        /> */}
                                        <span className='coa' style={{ cursor: 'pointer', alignSelf: 'flex-start' }} onClick={() => {
                                            printCoa(selectedProduct?.id)
                                        }}>
                                        CoA
                                        </span>
                                    </div>
                                    <div className="item-content">
                                        <div className="left-container">
                                            <h4>Excitation Wavelength</h4>
                                            <h4>Emission Wavelength</h4>
                                            <h4>Extinction Coefficient</h4>
                                            <h4>Molecular Weight</h4>
                                            <h4>Emission Color</h4>
                                            <h4>Buffer</h4>
                                            <h4>pH</h4>
                                            <h4>Storage</h4>
                                            {/* <h4>Applications</h4> */}
                                            {/* <h4>Price</h4> */}
                                        </div>
                                        <div className="right-container">
                                            <h4>{selectedProduct?.excitation_wavelength ? `${selectedProduct?.excitation_wavelength} nm` : '-'}</h4>
                                            <h4>{selectedProduct?.emission_wavelength ? `${selectedProduct?.emission_wavelength} nm` : '-'}</h4>
                                            <h4>{selectedProduct?.extinction_coefficient.includes(',') ? selectedProduct?.extinction_coefficient : Number(selectedProduct?.extinction_coefficient)?.toLocaleString('en-US')}</h4>
                                            <h4>{selectedProduct?.molecular_weight ? `${selectedProduct?.molecular_weight} Da` : '-'}</h4>
                                            <h4>{selectedProduct?.emission_color ? selectedProduct?.emission_color : '-'}</h4>
                                            <h4>{selectedProduct?.buffer_molarity ? `${selectedProduct?.buffer_molarity}mM` : "-"} {selectedProduct?.buffer ? selectedProduct?.buffer : "-"}</h4>
                                            <h4>{selectedProduct?.ph ? selectedProduct?.ph : '-'}</h4>
                                            <h4>{selectedProduct?.storage?.length ? selectedProduct?.storage.map((item) => (item + '°C')).join(', ') : '-'}</h4>
                                            {/* <div className="application-container">
                                                {selectedProduct?.metadata?.protein_application?.map((item) => {
                                                    return <span onClick={() => {
                                                        setModalName(item.name)
                                                        setOpenMethodologyModal(true)
                                                    }} data-tooltip-id="applications" data-tooltip-content={item.name}>{item.abbr}</span>
                                                })
                                                }
                                                {selectedProduct?.metadata?.microscopy_application?.map((item) => {
                                                    return <span onClick={() => {
                                                        setModalName(item.name)
                                                        setOpenMethodologyModal(true)
                                                    }} data-tooltip-id="applications" data-tooltip-content={item.name}>{item.abbr}</span>
                                                })
                                                }
                                            </div>
                                            <h4>${(selectedProduct?.variants[0]?.prices[0]?.amount / 100)?.toFixed(2)}</h4> */}
                                        </div>
                                    </div>
                                    <div className="item-content">
                                        <div className="left-container" style={{ width: '25%' }}>
                                            <h4>Applications</h4>
                                        </div>
                                        <div className="right-container">
                                            <div className="application-container">
                                                {selectedProduct?.metadata?.protein_application?.map((item) => {
                                                    return <span onClick={() => {
                                                        setModalName(item.name)
                                                        setOpenMethodologyModal(true)
                                                    }} data-tooltip-id="applications" data-tooltip-content={item.name}>{item.abbr}</span>
                                                })
                                                }
                                                {selectedProduct?.metadata?.microscopy_application?.map((item) => {
                                                    return <span onClick={() => {
                                                        setModalName(item.name)
                                                        setOpenMethodologyModal(true)
                                                    }} data-tooltip-id="applications" data-tooltip-content={item.name}>{item.abbr}</span>
                                                })
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <div className="item-content">
                                        <div className="left-container">
                                            <h4>Price / 100 µL Unit Size</h4>
                                        </div>
                                        <div className="right-container">
                                            <h4>${(selectedProduct?.variants[0]?.prices[0]?.amount / 100)?.toFixed(2)}</h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="item-image-container">
                                    {images && images.length > 0 && <div className="images-container">
                                        {images[imageSlideIndex]?.map((item, index) => {
                                            return <div className="image" onClick={() => {
                                                setOpenMethodologyModal(true)
                                                setModalName(item.name)
                                            }} key={index}>
                                                {isVideoFile(item) ? 
                                                <video src={getMp4Url(item.url)} muted autoPlay loop playsInline /> :
                                                <img src={item.url || toAbsoluteUrl('/media/methodologyplaceholder.png')} alt="" />
                                                }
                                                <h6>{item.name}</h6>
                                            </div>
                                        })}
                                    </div>
                                        // :
                                        // <div className='no-orders' style={{ height: '300px' }}>
                                        //     <span className='icon'>
                                        //         <i class="far fa-images"></i>
                                        //     </span>
                                        //     <p>No Images Available.</p>
                                        // </div>
                                    }
                                    {images && images.length > 1 && <div className="buttons-container">
                                        <button onClick={() => {
                                            if (imageSlideIndex > 0) {
                                                setImageSlideIndex(imageSlideIndex - 1)
                                            } else {
                                                setImageSlideIndex(0)
                                            }
                                        }}><i className='fas fa-chevron-left'></i></button>
                                        <div className="dots">
                                            {
                                                images.map((item, index) => {
                                                    return <span className={imageSlideIndex === index ? "active-dot" : ""} key={index}></span>
                                                })
                                            }
                                            {/* <span className='active-dot'></span>
                                <span></span>
                                <span></span> */}
                                        </div>
                                        <button
                                            onClick={() => {
                                                if (imageSlideIndex < images.length - 1) {
                                                    setImageSlideIndex(imageSlideIndex + 1)
                                                } else {
                                                    setImageSlideIndex(images.length - 1)
                                                }
                                            }}
                                        ><i className='fas fa-chevron-right'></i></button>
                                    </div>
                                    }
                                </div>
                                {/* <div className="item-image-container">
                                    <div className="images-container">
                                        <div className="image" onClick={() => setOpenMethodologyModal(true)}>
                                            <img src={toAbsoluteUrl('/media/preview1.png')} alt="" />
                                            <h6>Western Blot</h6>
                                        </div>
                                        <div className="image" onClick={() => setOpenMethodologyModal(true)}>
                                            <img src={toAbsoluteUrl('/media/preview2.png')} alt="" />
                                            <h6>Multiplexed Immunoassay​</h6>
                                        </div>
                                        <div className="image" onClick={() => setOpenMethodologyModal(true)}>
                                            <img src={toAbsoluteUrl('/media/preview3.png')} alt="" />
                                            <h6>Multiplexed Protein Expression and Host Cell Protein​</h6>
                                        </div>
                                        <div className="image" onClick={() => setOpenMethodologyModal(true)}>
                                            <img src={toAbsoluteUrl('/media/preview4.png')} alt="" />
                                            <h6>Widefield​</h6>
                                        </div>
                                    </div>
                                    <div className="buttons-container">
                                        <button><i className='fas fa-chevron-left'></i></button>
                                        <div className="dots">
                                            <span className='active-dot'></span>
                                            <span></span>
                                            <span></span>
                                        </div>
                                        <button><i className='fas fa-chevron-right'></i></button>
                                    </div>
                                </div> */}
                              {selectedProduct?.metadata?.reference && selectedProduct?.metadata?.reference?.some((item) => isValidURL(item)) &&  
                              <div className='references-container'>
                                    <h4>References</h4>
                                    {
                                        selectedProduct?.metadata?.reference?.map((item, index) => {
                                            if (isValidURL(item)){
                                                return <a href={item.includes("https") ? item : `https://${item}`} target="_blank" key={index}>{item}</a>
                                            }
                                    })}
                                </div>
                                }
                                <div className="add-cart-container">
                                    <p style={{ padding: '12px 10px', background: '#FFF2E5', borderRadius: '12px', color: '#DC4405', fontWeight: 700, textAlign: 'center', fontSize: '16px', lineHeight: '24px', width: '100%' }}>Identifyn™ Super Resolution Vetted
                                        Premium Research Products are Available through
                                        our Preferred Industry Partners</p>
                                    {/* <button className='btn add-btn'
                                        disabled={isLoading}
                                        onClick={() => tempDisable ? setTempDisable(false) : AddToCart()}
                                    >
                                        <i className='fal fa-shopping-cart'></i>
                                        Add to Cart</button>
                                    <button className='btn buy-btn'
                                        disabled={isLoading}
                                        onClick={() => tempDisable ? setTempDisable(false) : BuyProduct()}
                                    >Buy Now</button> */}
                                </div>
                            </div>
                        </>
                        :
                        <div className='no-orders' style={{ height: '100vh' }}>
                            <span className='icon'>
                                <i class="fal fa-boxes"></i>
                            </span>
                            {selectedProduct && productSelection.host && productSelection.species_reactivity && productSelection.full_length && dyeBtns ?

                                <p>No Product Available For this selection.</p>
                                :
                                <p>Select from the product list from the left
                                    to see the product view.</p>
                            }
                        </div>
                    }
                </div>
                <ReactTooltip
                    id="applications"
                    place="top"
                    variant="light"
                />
                <div className="print-map" style={{ display: 'none' }}>
                    <Coa reference={coARef} selectedProduct={selectedProduct} productCategory={selectedProduct?.mid_code.search("PA") >= 0 ? 'PA' : selectedProduct?.mid_code.search("SA") >= 0 ? 'SA' : 'DC'} />
                </div>
            </Wrapper>
            {openMethodologyModal && <MethodologyModal setOpenMethodologyModal={setOpenMethodologyModal} selectedProduct={selectedProduct} modalName={modalName} setModalName={setModalName} />}
        </Layout>
    )
}

export default Index


