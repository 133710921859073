import styled from "styled-components";
import { toAbsoluteUrl } from "../../../utils/assetHelper";

export const Wrapper = styled.div`
.contact-container{
background: url(${toAbsoluteUrl('/media/services/contact.png')}) no-repeat;
background-position: 0 center;
background-size: 100% 100%;
@media screen and (max-width:1100px) {
background: url(${toAbsoluteUrl('/media/services/contactmobile.png')}) no-repeat;
background-size: 100% 100%;
}
}
`

export const ServiceHeader = styled.div`
margin-top: 75px;
display: flex;
flex-direction: column;
gap: 32px;
margin-bottom: 48px;
 img {
 width:100%;
 object-fit: cover;
 }

 .subhero-img{
 width:60%;
 margin: 0 auto;

img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

@media screen and (max-width:1100px) {
    width: 90%; 
}
}
`

export const ServiceContent = styled.div`
    width: 98vw;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    gap: 48px;

h2 {
font-size: 36px;
 font-weight: 800;
 line-height: 45px;
 text-align: center;

 span {
    background:linear-gradient(227deg, #DC4405 0%, #FF8D24 85.6%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: 600;
    }

@media screen  and (max-width:1100px) {
    font-size: 24px;
}
}

.header-card-container{
    display: flex;
    flex-direction: column;
    gap: 24px;
    width: 960px;
    margin: 0 auto;

.card{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
    background: #FFF2E5;
    padding: 44px 96px;
    font-size: #1e1e1e;
    font-size: 20px;
    font-weight: 500;
    text-align: center;

    @media screen and (max-width: 1100px)  {
        padding: 24px;
    }
}

@media screen and (max-width: 1100px) {
    width: 98%;
}
}

.antibodies-card-container{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 24px;
    align-items: center;

    @media screen and (max-width: 1300px) {
        grid-template-columns: 1fr;
    }

.antibody-card{
display: flex;
flex-direction: column;
gap: 32px;

.text-card{
    background: #F1EEFC;
    padding: 32px;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    gap: 15px;
    h4, p{
        font-size: 20px;
        font-weight: 400;
        line-height: 28px;
        text-align: center;
        color: #1e1e1e;
        span {
            font-weight: 700;
        }
    }
}

img {
width: 100%;
object-fit: cover;

@media screen and (max-width: 1300px) {
        width: 50%;
        margin: 0 auto;
    }
}
}

}

`

export const ServiceStepsContainer = styled.div`
width: 98vw;
margin: 0 auto;
margin-bottom: 48px;
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
gap: 48px;
`

export const CardContainer = styled.div`
background: ${props => props.background || "#F1EEFC"};
color: ${props => props.textColor || "#1E1E1E"};
display: flex;
flex-direction: column;
justify-content: ${props => props.justifyContent || "center"};
padding: ${props => props.padding || "32px"};
border-radius: 20px;
width: ${props => props.width || "100%"};
margin: 0 auto;

@media screen and (max-width: 1100px) {
    padding: 16px;
    width: 100%;
}

h2 {
    font-size: 36px;
    font-weight: 700;
    line-height: 45px;
    text-align: ${props => props.textAlignH2 || "center"};
    margin-bottom: ${props => props.mb_h2 || "0"};

    span {
        background:linear-gradient(227deg, #DC4405 0%, #FF8D24 85.6%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }

    @media screen and (max-width: 1100px) {
        font-size: 24px;  
    }
}

h4 {
font-weight: 600;
font-size: 20px;
line-height: 28px;
text-align: ${props => props.textAlignH4 || "center"};
margin-bottom: ${props => props.mb_h4 || "0"};
}

h5 {
font-weight: 600;
font-size: 16px;
line-height: 24px;
text-align: ${props => props.textAlignH5 || "left"};
margin-bottom: ${props => props.mb_h5 || "0"};
}

p {
font-weight: 400;
font-size: 20px;
line-height: 28px;
text-align: ${props => props.textAlignP || "center"};
margin-bottom: ${props => props.mb_p || "0"};
span {
    font-weight: 700;
}
.gradient{
    background:linear-gradient(227deg, #DC4405 0%, #FF8D24 85.6%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
}

small {
font-size: 12px;
font-weight: 400;
line-height: 24px;
text-align: ${props => props.textAlignSmall || "left"};
margin-bottom: ${props => props.mb_small || "0"};

span {
    font-weight: 700;
}
}

.flex-wrapper {
    display: flex;
    flex-direction: ${props => props.flexDirection || "column"};
    gap: ${props => props.gap || "5px"};
    align-items: ${props => props.alignItems || "start"};
    justify-content: ${props => props.justifyContent || "start"};
    margin-bottom: ${props => props.mbWrapper || "0"};
}

video {
    width: 80%;
    height: 100%;
    margin-bottom: 24px;
    border-radius: 6px;

    @media screen and (max-width: 1100px) {
        width: 100%;
    }
}

`

export const HeadingContainer = styled.h2`
font-size: 36px;
font-weight: 700;
line-height: 45px;
text-align: center;

@media screen and (max-width: 1100px) {
    font-size: 24px;
}
span {
    background:linear-gradient(227deg, #DC4405 0%, #FF8D24 85.6%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
`

export const WrapperSmallGap = styled.div`
    display: flex;
    flex-direction: ${props => props.flexDirection || "column"};
    gap: ${props => props.gap || "24px"};
    align-items: ${props => props.alignItems || "start"};
    justify-content: ${props => props.justifyContent || "start"};
    margin-bottom: ${props => props.mbWrapper || "0"};
    width: ${props => props.width || "100%"};
    margin: 0 auto;

    @media screen and (max-width: 1100px) {
       flex-direction: ${props => props.flexDirectionMobile || "column"};
       width: 100%;
    }

img {
    width: ${props => props.imgWidth || "55%"};
    object-fit: cover;
    @media screen and (max-width: 1100px) {
        width: 80% !important; 
    }
}

`